import { CompanyCategory } from "@repo/types/companyApi.types";

export const CompanyCategoryExtended = {
  None: "None" as CompanyCategory,
  ...CompanyCategory,
};

export const companyCategoryMap = Object.values(CompanyCategoryExtended).filter(
  (category) => category !== ("None" as CompanyCategory)
);
