import { CustomerCompanySummaryDtoPaginatedList } from "@repo/types/companyApi.types";
import { httpClient } from "../../http-client";
import { companyApiPaths } from "./paths";

export const getCustomersList = async (
  tenantId: string,
  {
    pageNumber,
    pageSize,
  }: {
    pageNumber: number;
    pageSize: number;
  }
) => {
  const response = await httpClient.get<CustomerCompanySummaryDtoPaginatedList>(
    companyApiPaths.customers.base(tenantId, pageNumber, pageSize)
  );

  return response.data;
};
