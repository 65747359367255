import {
  Box,
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { createPlan, CreatePlanRequest } from "@repo/api-config/services/root";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import { PlanAccessibility } from "@repo/types/rootApi.types";
import { Button, Card, ErrorMessage, Select, TextInput } from "@repo/ui";
import {
  cmsRoutes,
  pricePerMonthValidator,
  pricePerYearValidator,
  requiredNumberValidator,
  requiredStringValidator,
  useNotificationsContext,
  useServerErrorFormatter,
} from "@repo/utils";
import { useForm } from "@tanstack/react-form";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { valibotValidator } from "@tanstack/valibot-form-adapter";
import { AxiosError } from "axios";
import { useContext } from "react";

type NewPlanFormData = Pick<CreatePlanRequest, "accessibility" | "name"> & {
  pricePerMonth: number | "";
  pricePerYear: number | "";
  operatorsLimit: number | "";
  rewardsLimit: number | "";
};

export const NewPlan = () => {
  const { showNotification } = useNotificationsContext();
  const { errorMessage, setError } = useServerErrorFormatter();
  const navigate = useNavigate();
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");

  const { mutate: createPlanMutation, isPending } = useMutation<
    void,
    AxiosError,
    CreatePlanRequest
  >({
    mutationFn: (values) => createPlan(values),
    onError: setError,
    onSuccess: () => {
      showNotification(t("Plans.planAdded"));
      navigate({ to: cmsRoutes.plans.base });
    },
  });

  const form = useForm<NewPlanFormData, any>({
    defaultValues: {
      name: "",
      accessibility: PlanAccessibility.Standalone,
      operatorsLimit: "",
      pricePerMonth: "",
      pricePerYear: "",
      rewardsLimit: "",
    },
    onSubmit: ({ value }) => {
      createPlanMutation(value as CreatePlanRequest);
    },
    validatorAdapter: valibotValidator(),
  });

  return (
    <Card
      title={t("Plans.addPlan")}
      sx={{
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        "& h6": {
          width: { xs: "100%", sm: "40%" },
        },
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flex: 1 }}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <FormGroup sx={{ gap: 2 }}>
            <TextInput
              form={form}
              name="name"
              validators={{
                onChange: requiredStringValidator,
              }}
              required
              label={t("Plans.form.name")}
            />
            <TextInput
              form={form}
              name="pricePerMonth"
              validators={{
                onChangeListenTo: ["pricePerYear"],
                onChange: pricePerMonthValidator,
              }}
              type="number"
              required
              label={t("Plans.form.pricePerMonth")}
              numberType="float"
            />
            <TextInput
              form={form}
              name="pricePerYear"
              validators={{
                onChangeListenTo: ["pricePerMonth"],
                onChange: pricePerYearValidator,
              }}
              type="number"
              required
              label={t("Plans.form.pricePerYear")}
              numberType="float"
            />
            <TextInput
              form={form}
              name="operatorsLimit"
              validators={{
                onChange: requiredNumberValidator,
              }}
              type="number"
              required
              label={t("Plans.form.operatorsLimit")}
            />
            <TextInput
              form={form}
              name="rewardsLimit"
              validators={{
                onChange: requiredNumberValidator,
              }}
              type="number"
              required
              label={t("Plans.form.rewardsLimit")}
            />
            <FormControl>
              <InputLabel id="accessibility">
                {t("Plans.form.accessibility.header")}
              </InputLabel>
              <Select
                form={form}
                name="accessibility"
                labelId="accessibility"
                id="accessibility"
                autoWidth
                label={t("Plans.form.accessibility.header")}
              >
                <MenuItem value={PlanAccessibility.Standalone}>
                  {t("Plans.form.accessibility.Standalone")}
                </MenuItem>
                <MenuItem value={PlanAccessibility.Custom}>
                  {t("Plans.form.accessibility.Custom")}
                </MenuItem>
              </Select>
            </FormControl>
          </FormGroup>
          {errorMessage ? <ErrorMessage errorMessage={errorMessage} /> : null}
          <form.Subscribe
            selector={(state) => state.isValid && !state.isPristine}
            children={(isValid) => (
              <Button
                type="submit"
                sx={{ mt: 4, alignSelf: "flex-end" }}
                variant="contained"
                disabled={!isValid || isPending}
              >
                {t("Plans.form.save")}
              </Button>
            )}
          />
        </Box>
      </Box>
    </Card>
  );
};
