import { Box } from "@mui/material";
import { getTenantId } from "@repo/api-config";
import { getCustomersList } from "@repo/api-config/services/company";
import { DataTable, RowsPerPageOption } from "@repo/ui";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

export const CustomersPage = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState<RowsPerPageOption>(10);
  const tenantId = getTenantId();

  const { data: customersList, isLoading } = useQuery({
    queryFn: () => getCustomersList(tenantId!, { pageNumber, pageSize }),
    queryKey: ["Customers", pageNumber, pageSize],
    enabled: !!tenantId,
  });

  return (
    <Box>
      <DataTable
        handlePageChange={setPageNumber}
        handlePageSizeChange={setPageSize}
        pageSize={pageSize}
        isFetching={isLoading}
        data={customersList}
      />
    </Box>
  );
};
