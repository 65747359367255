import { createFileRoute, redirect } from "@tanstack/react-router";
import { NewLocation } from "../../../pages/Locations/NewLocation";

export const Route = createFileRoute("/_private/locations/new")({
  component: () => <NewLocation />,
  beforeLoad: ({ context }) => {
    const { canCreate } = context.permissions.locations;

    if (!canCreate) {
      throw redirect<any>({
        to: "/",
      });
    }
  },
});
