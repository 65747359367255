import { createFileRoute, redirect } from "@tanstack/react-router";
import { EmployeesPage } from "../../../pages/Employees/EmployeesPage";

export const Route = createFileRoute("/_private/employees/")({
  component: () => <EmployeesPage />,
  beforeLoad: ({ context }) => {
    const { canRead } = context.permissions.employees;

    if (!canRead) {
      throw redirect<any>({
        to: "/",
      });
    }
  },
});
