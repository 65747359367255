import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import { getTenantId } from "@repo/api-config";
import {
  changeSubscription,
  getPlans,
} from "@repo/api-config/services/company";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import {
  SubscriptionFrequency,
  SubscriptionVm,
  UpdateSubscriptionCommand,
} from "@repo/types/companyApi.types";
import { ErrorMessage, LoadingButton, Select } from "@repo/ui";
import { useForm } from "@tanstack/react-form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { PlanVerification } from "./PlanVerification";
import { AxiosError } from "axios";
import { useNotificationsContext, useServerErrorFormatter } from "@repo/utils";

interface SubscriptionSettingsModalProps {
  open: boolean;
  onClose: () => void;
  currentSubscription: SubscriptionVm;
}

export const SubscriptionSettingsModal = ({
  open,
  onClose,
  currentSubscription,
}: SubscriptionSettingsModalProps) => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");
  const tenantId = getTenantId();
  const { showNotification } = useNotificationsContext();
  const { errorMessage, setError } = useServerErrorFormatter();

  const { data: subscriptionOptions } = useQuery({
    queryKey: ["subscriptions"],
    queryFn: () => getPlans(tenantId!, { pageNumber: 1, pageSize: 10 }),
  });

  const queryClient = useQueryClient();

  const { mutate: updateSubscription, isPending } = useMutation<
    void,
    AxiosError,
    UpdateSubscriptionCommand
  >({
    mutationFn: (values) =>
      changeSubscription(values.subscriptionId, tenantId!, values),
    onError: setError,
    onSuccess: () => {
      showNotification(
        t("Rewards.subscriptionOptions.subscriptionSettingsModal.updated")
      );
      onClose();
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: ["currentSubscription"],
        });
      }, 1000);
    },
  });

  const form = useForm<UpdateSubscriptionCommand, any>({
    defaultValues: {
      frequency: currentSubscription.frequency,
      planId: currentSubscription.ratePlan?.id || 0,
      subscriptionId: currentSubscription.id,
    },
    onSubmit: ({ value }) => {
      updateSubscription(value);
    },
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        elevation: 4,
      }}
    >
      <DialogTitle
        variant="h5"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontWeight: 700,
        }}
      >
        {t("Rewards.subscriptionOptions.subscriptionSettingsModal.header")}
        <IconButton onClick={onClose}>
          <Close
            sx={{
              fill: (theme) => theme.palette.text.primary,
            }}
          />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        <Box
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            form.handleSubmit();
          }}
        >
          <Typography variant="h6" fontWeight={700}>
            {t(
              "Rewards.subscriptionOptions.subscriptionSettingsModal.paymentFrequency"
            )}
          </Typography>
          <form.Subscribe
            selector={(state) => [state.values.frequency]}
            children={([frequency]) => (
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  mt: 2,
                }}
              >
                <Button
                  variant={
                    frequency === SubscriptionFrequency.Monthly
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => {
                    form.setFieldValue(
                      "frequency",
                      SubscriptionFrequency.Monthly
                    );
                  }}
                >
                  {t(
                    "Rewards.subscriptionOptions.subscriptionSettingsModal.frequency.Monthly"
                  )}{" "}
                  {currentSubscription.frequency ===
                    SubscriptionFrequency.Monthly &&
                    t(
                      "Rewards.subscriptionOptions.subscriptionSettingsModal.currentFrequency"
                    )}
                </Button>
                <Button
                  variant={
                    frequency === SubscriptionFrequency.Annually
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => {
                    form.setFieldValue(
                      "frequency",
                      SubscriptionFrequency.Annually
                    );
                  }}
                >
                  {t(
                    "Rewards.subscriptionOptions.subscriptionSettingsModal.frequency.Annually"
                  )}{" "}
                  {currentSubscription.frequency ===
                    SubscriptionFrequency.Annually &&
                    t(
                      "Rewards.subscriptionOptions.subscriptionSettingsModal.currentFrequency"
                    )}
                </Button>
              </Box>
            )}
          />
          <Typography variant="h5" fontWeight={700} mt={3} mb={2}>
            {t(
              "Rewards.subscriptionOptions.subscriptionSettingsModal.changePlan"
            )}
          </Typography>
          <Select
            form={form}
            name="planId"
            labelId="planId"
            id="planId"
            fullWidth
            label=""
          >
            {subscriptionOptions?.items.map(({ id, name }) => (
              <MenuItem value={id} key={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
          <form.Subscribe
            selector={(state) => [state.values.planId, state.values.frequency]}
            children={([planId, frequency]) =>
              planId && (
                <>
                  <Typography
                    textAlign="right"
                    mt={3}
                    variant="h6"
                    color={(theme) => theme.palette.text.secondary}
                  >
                    {(frequency === SubscriptionFrequency.Annually
                      ? subscriptionOptions?.items.find(
                          (plan) => plan.id === (planId as number)
                        )?.pricePerYear
                      : subscriptionOptions?.items.find(
                          (plan) => plan.id === (planId as number)
                        )?.pricePerMonth
                    )?.toLocaleString(lang, {
                      currency: "PLN",
                      style: "currency",
                    })}{" "}
                    {t(
                      `Rewards.subscriptionOptions.subscriptionSettingsModal.priceFrequency.${frequency}` as `Rewards.subscriptionOptions.subscriptionSettingsModal.priceFrequency.${SubscriptionFrequency}`
                    )}
                  </Typography>

                  <PlanVerification
                    planData={
                      subscriptionOptions?.items.find(
                        (plan) => plan.id === (planId as number)
                      )!
                    }
                    appendContent={(eligible) => (
                      <LoadingButton
                        text={t(
                          "Rewards.subscriptionOptions.subscriptionSettingsModal.update"
                        )}
                        isLoading={isPending}
                        disabled={!eligible}
                        fullWidth={false}
                        sx={{ alignSelf: "end" }}
                      />
                    )}
                  />
                  <ErrorMessage errorMessage={errorMessage} />
                </>
              )
            }
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
