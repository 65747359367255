import { PaginationParams, StatisticsChartTimePeriod } from "../../types";

const basePath = "/root/api/v1";

export const rootApiPaths = {
  auth: {
    base: `${basePath}/auth`,
    login: `${basePath}/auth/login`,
    refresh: `${basePath}/auth/refresh`,
    info: `${basePath}/auth/info`,
  },
  companies: {
    base: ({ pageParam }: PaginationParams) =>
      `${basePath}/companies?pageNumber=${pageParam.pageNumber}&pageSize=${pageParam.pageSize}`,
    getCompanyById: (identifier: string) =>
      `${basePath}/companies/${identifier}`,
    update: (identifier: string) => `${basePath}/companies/${identifier}`,
    confirm: (identifier: string) =>
      `${basePath}/companies/verification/${identifier}`,
  },
  plans: {
    base: (pageNumber: number, pageSize: number) =>
      `${basePath}/plans?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    create: `${basePath}/plans`,
    delete: (id: number) => `${basePath}/plans/${id}`,
  },
  statistics: {
    base: `${basePath}/statistics`,
    chart: (period: StatisticsChartTimePeriod) =>
      `${basePath}/statistics/chart?period=${period}`,
  },
};
