import { Box, CircularProgress } from "@mui/material";
import { getTenantId } from "@repo/api-config";
import {
  getCompanyDetails,
  updateCompany,
} from "@repo/api-config/services/company";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import { CompanyDto, UpdateCompanyCommand } from "@repo/types/companyApi.types";
import { Card, ErrorMessage, LoadingButton, TextInput } from "@repo/ui";
import {
  requiredStringValidator,
  useNotificationsContext,
  useServerErrorFormatter,
} from "@repo/utils";
import { useForm } from "@tanstack/react-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { valibotValidator } from "@tanstack/valibot-form-adapter";
import { AxiosError } from "axios";
import { useContext, useEffect } from "react";

const CompanyInfoForm = ({ data }: { data: CompanyDto }) => {
  const form = useForm({
    defaultValues: {
      companyName: data.name,
      phoneNumber: data.phoneNumber,
    },
    onSubmit: async ({ value }) => {
      mutate(value);
    },
    validatorAdapter: valibotValidator(),
  });

  useEffect(() => {
    if (data) form.setFieldValue("companyName", data.name);
    if (data) form.setFieldValue("phoneNumber", data.phoneNumber);
  }, [data]);

  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");
  const { showNotification } = useNotificationsContext();
  const tenantId = getTenantId();
  const { errorMessage, setError } = useServerErrorFormatter();

  const { mutate, isPending } = useMutation<
    void,
    AxiosError,
    Omit<UpdateCompanyCommand, "multiplier">
  >({
    mutationFn: (value) => updateCompany(tenantId!, value),
    onSuccess: () => {
      showNotification(t("Rewards.multiplierSaved"));
    },
    onError: (error) => setError(error),
  });

  return (
    <Box
      component="form"
      sx={{ display: "flex", flex: 1 }}
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          gap: 2,
        }}
      >
        <TextInput
          form={form}
          name="companyName"
          label={t("Settings.companyInfoForm.companyName")}
          required
          validators={{
            onChange: requiredStringValidator,
          }}
        />
        <TextInput
          form={form}
          name="phoneNumber"
          type="tel"
          label={t("Settings.companyInfoForm.phoneNumber")}
          required
          validators={{
            onChange: requiredStringValidator,
          }}
        />
        {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
        <LoadingButton
          sx={{
            mt: 4,
            alignSelf: "flex-end",
          }}
          fullWidth={false}
          text={t("Settings.companyInfoForm.save")}
          isLoading={isPending}
        />
      </Box>
    </Box>
  );
};

const CompanyInfo = () => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");

  const tenantId = getTenantId();

  const { errorMessage, setError } = useServerErrorFormatter();

  const { data, isLoading, error } = useQuery({
    queryKey: ["CompanyInfo"],
    queryFn: () => getCompanyDetails(tenantId!),
  });

  useEffect(() => {
    if (error) setError(error as AxiosError);
  }, [error]);

  return (
    <Card
      title={t("Settings.companyInfo")}
      sx={{
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        "& h6": {
          width: { xs: "100%", sm: "40%" },
        },
      }}
    >
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {errorMessage && !data && <ErrorMessage errorMessage={errorMessage} />}
      {data && <CompanyInfoForm data={data} />}
    </Card>
  );
};

export default CompanyInfo;
