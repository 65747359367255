import { Cancel, CheckCircle } from "@mui/icons-material";
import { Box, CircularProgress, Typography } from "@mui/material";
import { getTenantId } from "@repo/api-config";
import {
  getEmployeesList,
  getLocationsList,
  getRewardsList,
} from "@repo/api-config/services/company";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import { PlanPublicDto } from "@repo/types/companyApi.types";
import { getCookie } from "@repo/utils";
import { useQuery } from "@tanstack/react-query";
import { ReactNode, useContext, useMemo } from "react";

interface PlanVerificationProps {
  planData: PlanPublicDto;
  prependContent?: (eligible: boolean) => ReactNode;
  appendContent?: (eligible: boolean) => ReactNode;
  type?: "new" | "edit";
}

export const PlanVerification = ({
  planData,
  appendContent,
  prependContent,
  type = "edit",
}: PlanVerificationProps) => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");
  const tenantId = getTenantId();
  const role = getCookie("loymeeUserRole");

  const { data: rewards } = useQuery({
    queryKey: ["rewards"],
    queryFn: () => getRewardsList(tenantId!),
    enabled: !!tenantId,
    refetchOnMount: true,
  });

  const { data: employees } = useQuery({
    queryFn: () =>
      getEmployeesList(
        tenantId!,
        role === "PendingOwner" ? "PENDINGOPERATOR" : "OPERATOR"
      ),
    queryKey: ["employees"],
    enabled: !!tenantId,
    refetchOnMount: true,
  });

  const { data: locationsList } = useQuery({
    queryFn: () => getLocationsList(tenantId!),
    queryKey: ["locations"],
    refetchOnMount: true,
  });

  const rewardEligibility = useMemo(
    () => ({
      rewards: rewards ? rewards.length <= planData.rewardsLimit : false,
      employees: employees
        ? employees.length <= planData.operatorsLimit
        : false,
      locations: locationsList
        ? locationsList.length <= planData.locationsLimit
        : false,
    }),
    [planData, rewards, employees, locationsList]
  );

  if (!rewards || !employees || !locationsList)
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  return (
    <>
      {prependContent &&
        prependContent(Object.values(rewardEligibility).every((v) => v))}
      {(type === "edit" ||
        Object.values(rewardEligibility).some((v) => !v)) && (
        <Box sx={{ mt: 5, display: "flex", flexDirection: "column", gap: 2 }}>
          {!Object.values(rewardEligibility).every((v) => v) && (
            <Typography
              variant="body2"
              fontWeight={700}
              color={(theme) => theme.palette.error.main}
            >
              {type === "new"
                ? t(
                    "Rewards.subscriptionOptions.planVerification.cantActivate",
                    { name: planData.name }
                  )
                : t("Rewards.subscriptionOptions.planVerification.cantChange")}
            </Typography>
          )}
          <Box sx={{ display: "flex", gap: 0.5 }}>
            {rewardEligibility.rewards ? (
              <CheckCircle color="success" />
            ) : (
              <Cancel color="error" />
            )}
            <Typography>
              {t("Rewards.subscriptionOptions.planVerification.rewardCount")}:{" "}
              <Typography
                component="span"
                fontWeight={700}
                color={(theme) =>
                  rewardEligibility.rewards
                    ? theme.palette.success.main
                    : theme.palette.error.main
                }
              >
                {rewards?.length}/{planData.rewardsLimit}
              </Typography>
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 0.5 }}>
            {rewardEligibility.employees ? (
              <CheckCircle color="success" />
            ) : (
              <Cancel color="error" />
            )}
            <Typography>
              {t("Rewards.subscriptionOptions.planVerification.employeeCount")}:{" "}
              <Typography
                component="span"
                fontWeight={700}
                color={(theme) =>
                  rewardEligibility.employees
                    ? theme.palette.success.main
                    : theme.palette.error.main
                }
              >
                {employees?.length}/{planData.operatorsLimit}
              </Typography>
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 0.5 }}>
            {rewardEligibility.locations ? (
              <CheckCircle color="success" />
            ) : (
              <Cancel color="error" />
            )}
            <Typography>
              {t("Rewards.subscriptionOptions.planVerification.locationCount")}:{" "}
              <Typography
                component="span"
                fontWeight={700}
                color={(theme) =>
                  rewardEligibility.locations
                    ? theme.palette.success.main
                    : theme.palette.error.main
                }
              >
                {locationsList?.length}/{planData.locationsLimit}
              </Typography>
            </Typography>
          </Box>
        </Box>
      )}
      {appendContent &&
        appendContent(Object.values(rewardEligibility).every((v) => v))}
    </>
  );
};
