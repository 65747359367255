"use client";

import { Box, FormGroup } from "@mui/material";
import {
  Button,
  Card,
  ErrorMessage,
  FunctionNotAvailable,
  TextInput,
} from "@repo/ui";
import { useForm } from "@tanstack/react-form";
import { valibotValidator } from "@tanstack/valibot-form-adapter";
import {
  notificationContentValidator,
  notificationTitleValidator,
  // optionalEmailValidator,
  useNotificationsContext,
  useServerErrorFormatter,
} from "@repo/utils";
import { useMe } from "@repo/api-config";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getFlags,
  sendTopicNotification,
  // sendUserNotification,
} from "@repo/api-config/services/company";
import { AxiosError } from "axios";
import {
  SendPushNotificationToCompanySubscribersCommand,
  // SendPushNotificationToCustomerCommand,
  UserInfoResponse,
} from "@repo/types/companyApi.types";
import { useContext } from "react";
import { I18nContext, useTranslation } from "@repo/i18n-config";

const NotificationsPage = () => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");

  const { tenantIdentifier } = useMe("company");
  const { showNotification } = useNotificationsContext();
  const { setError, errorMessage } = useServerErrorFormatter();

  const { data: flags } = useQuery({
    queryKey: ["flags"],
    queryFn: () => getFlags(tenantIdentifier!),
    refetchOnMount: true,
  });

  const { mutate: sendTopicPush } = useMutation<
    UserInfoResponse,
    AxiosError,
    SendPushNotificationToCompanySubscribersCommand
  >({
    mutationFn: (values) => {
      setError(null);
      return sendTopicNotification(tenantIdentifier || "", values);
    },
    onSuccess: () => showNotification(t("Notifications.pushSent")),
    onError: setError,
  });

  // const { mutate: sendUserPush } = useMutation<
  //   UserInfoResponse,
  //   AxiosError,
  //   SendPushNotificationToCustomerCommand
  // >({
  //   mutationFn: (values) => {
  //     setError(null);
  //     return sendUserNotification(tenantIdentifier || "", values);
  //   },
  //   onSuccess: () =>
  //     showNotification(t("Notifications.pushSent")),
  //   onError: setError,
  // });

  const pushNotificationForm = useForm({
    defaultValues: {
      title: "",
      content: "",
    },
    onSubmit: async ({ value }) => {
      // if (value.userEmail)
      //   sendUserPush({
      //     body: value.content,
      //     title: value.title,
      //     customData: {},
      //     email: value.userEmail,
      //   });
      // else
      sendTopicPush({
        body: value.content,
        title: value.title,
        customData: {},
      });
    },
    validatorAdapter: valibotValidator(),
  });

  if (!flags?.emailToSubscribersLimit) return <FunctionNotAvailable />;

  return (
    <>
      <Card
        title={t("Notifications.header")}
        sx={{
          flexDirection: {
            xs: "column",
            md: "row",
          },
          "& h6": {
            width: { xs: "100%", md: "40%" },
          },
          mt: 2,
        }}
      >
        <Box
          component="form"
          sx={{ display: "flex", flex: 1 }}
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            pushNotificationForm.handleSubmit();
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <FormGroup sx={{ gap: 2 }}>
              <TextInput
                form={pushNotificationForm}
                name="title"
                validators={{
                  onChange: notificationTitleValidator,
                }}
                label={t("Notifications.form.subject")}
                required
                charLimit={20}
              />
              <TextInput
                form={pushNotificationForm}
                name="content"
                validators={{
                  onChange: notificationContentValidator,
                }}
                label={t("Notifications.form.content")}
                required
                multiline
                charLimit={100}
              />
              {/* <TextInput
                form={pushNotificationForm}
                name="userEmail"
                type="email"
                validators={{
                  onChange: optionalEmailValidator,
                }}
                label={t("Notifications.form.userEmail")}
              /> */}
            </FormGroup>
            {errorMessage ? <ErrorMessage errorMessage={errorMessage} /> : null}
            <pushNotificationForm.Subscribe
              selector={(state) => state.isValid && !state.isPristine}
              children={(isValid) => (
                <Button
                  type="submit"
                  sx={{ mt: 9, alignSelf: "flex-end" }}
                  variant="contained"
                  disabled={!isValid}
                >
                  {t("Notifications.form.submit")}
                </Button>
              )}
            />
          </Box>
        </Box>
      </Card>
    </>
  );
};
export default NotificationsPage;
