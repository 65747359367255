import { Box } from "@mui/material";
import { getCompaniesList } from "@repo/api-config/services/root";
import { DataTable, RowsPerPageOption } from "@repo/ui";
import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";

export const SubscriptionsPage = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState<RowsPerPageOption>(10);

  const { data, isLoading } = useQuery({
    queryFn: () => getCompaniesList({ pageParam: { pageNumber, pageSize } }),
    queryKey: ["Companies", pageNumber, pageSize],
  });

  const companiesList = useMemo(() => {
    if (data)
      return {
        ...data,
        items: data.items.map((obj) => {
          const {
            identifier,
            name,
            subscription,
            statistics,
            verificationStatus,
            isActive,
            ...rest
          } = obj;
          return {
            id: identifier,
            companyName: name,
            planName: subscription.planName,
            planPrice: {
              price: subscription.price,
              subscriptionFrequency: subscription.subscriptionFrequency,
            },
            subscriptionState: subscription.subscriptionState,
            ...rest,
          };
        }),
      };
  }, [data]);

  return (
    <Box>
      <DataTable
        handlePageChange={setPageNumber}
        handlePageSizeChange={setPageSize}
        pageSize={pageSize}
        isFetching={isLoading}
        data={companiesList}
      />
    </Box>
  );
};
