import { Box, Button } from "@mui/material";
import { deletePlan, getPlansList } from "@repo/api-config/services/root";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import {
  ConfirmModal,
  DataTable,
  LinkWrapper,
  RowsPerPageOption,
} from "@repo/ui";
import { cmsRoutes, useNotificationsContext } from "@repo/utils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useContext, useMemo, useState } from "react";
import { DeletedItem } from "../../@types";

export const PlansPage = () => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState<RowsPerPageOption>(10);

  const { data, isLoading, refetch } = useQuery({
    queryFn: () => getPlansList({ pageParam: { pageNumber, pageSize } }),
    queryKey: ["Plans", pageNumber, pageSize],
  });

  const { showNotification } = useNotificationsContext();

  const [deletedItem, setDeletedItem] = useState<DeletedItem>(null);
  const { mutate: deletePlanMutation, isPending: isDeleteMutationPending } =
    useMutation<any, AxiosError, any>({
      mutationFn: (id) => deletePlan(id),
      onSuccess: () => {
        showNotification(t("Plans.planDeleted"));
        setDeletedItem(null);
        refetch();
      },
      onError: () => {
        showNotification(t("Plans.planDeleteFail"), {
          type: "error",
        });
        setDeletedItem(null);
      },
    });

  const plansList = useMemo(() => {
    if (data)
      return {
        ...data,
        items: data.items.map((obj) => {
          const { created, ...rest } = obj;
          return { ...rest };
        }),
      };
  }, [data]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          alignSelf: "end",
          mt: -5,
          mb: 2,
        }}
      >
        <LinkWrapper to={cmsRoutes.plans.new}>
          <Button>{t("Plans.add")}</Button>
        </LinkWrapper>
      </Box>
      <DataTable
        handlePageChange={setPageNumber}
        handlePageSizeChange={setPageSize}
        pageSize={pageSize}
        isFetching={isLoading}
        data={plansList}
        showActions={{
          handleDelete: (id) => {
            setDeletedItem({
              id,
              name: plansList?.items.find((i) => i.id === id)?.name!,
            });
          },
          isDeleteMutationPending,
        }}
      />
      <ConfirmModal
        open={!!deletedItem?.id}
        handleNo={() => setDeletedItem({ ...deletedItem!, id: "" })}
        handleYes={() => {
          setDeletedItem({ ...deletedItem!, id: "" });
          deletePlanMutation(deletedItem!.id);
        }}
        text={t("Plans.planDeleteConfirm", { name: deletedItem?.name })}
      />
    </Box>
  );
};
