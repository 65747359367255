import {
  handleAuthResponse,
  clearUserAuth,
  isAuthenticated,
} from "./authUtils";
import { SignInRequest, signIn as signInService } from "../services/company";
import { getCookie } from "@repo/utils";
import { useQueryClient } from "@tanstack/react-query";

export interface CompanyAuthProps {
  signIn: (props: SignInRequest) => Promise<void>;
  logout: () => Promise<void>;
  isAuthenticated: () => boolean;
  getUserRole: () => string | null;
}

export const useCompanyAuth = (): CompanyAuthProps => {
  const queryClient = useQueryClient();

  const signIn = async ({
    email,
    password,
    tenantId,
  }: SignInRequest): Promise<void> => {
    const response = await signInService({
      email,
      password,
      tenantId,
    });
    return handleAuthResponse(response, { loymeeTenantId: tenantId }, true);
  };

  const getUserRole = () => {
    return getCookie("loymeeUserRole");
  };

  const logout = async (): Promise<void> => {
    queryClient.clear();
    clearUserAuth();
  };

  return {
    signIn,
    logout,
    isAuthenticated,
    getUserRole,
  };
};
