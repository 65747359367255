import { httpClient } from "../../http-client";
import { companyApiPaths } from "./paths";
import { SendPushNotificationToCompanySubscribersCommand } from "@repo/types/companyApi.types";

export const sendTopicNotification = async (
  tenantId: string,
  data: SendPushNotificationToCompanySubscribersCommand
) => {
  const response = await httpClient.post(
    companyApiPaths.notifications.topic(tenantId),
    data
  );

  return response.data;
};

export const sendUserNotification = async (
  tenantId: string,
  data: SendPushNotificationToCompanySubscribersCommand
) => {
  const response = await httpClient.post(
    companyApiPaths.notifications.user(tenantId),
    data
  );

  return response.data;
};
