import { Box } from "@mui/material";
import {
  DashboardCharts,
  DashboardInfoCard,
  DashboardPlansUsageChart,
  giftDarkIcon,
  qrDarkIcon,
  usersDarkIcon,
} from "@repo/ui";
import { getImageUrl } from "@repo/utils";
import { CorporateFare } from "@mui/icons-material";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { getStatistics } from "@repo/api-config/services/root";

export const DashboardRootLayout = () => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");

  const { data, isLoading, isError } = useQuery({
    queryFn: () => getStatistics(),
    queryKey: ["DashboardStatistics"],
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      <Box
        sx={{
          display: "grid",
          gap: 3,
          gridTemplateColumns: { sm: "1fr 1fr", lg: "1fr 1fr 1fr" },
          gridAutoRows: "max-content",
        }}
      >
        <DashboardInfoCard
          title={t("dashboard.scanCount")}
          content={data?.purchaseScanCount}
          icon={<img src={getImageUrl(qrDarkIcon)} alt="" />}
          isLoading={isLoading}
          isError={isError}
        />
        <DashboardInfoCard
          title={t("dashboard.userCount")}
          content={data?.existingCustomerCount}
          icon={<img src={getImageUrl(usersDarkIcon)} alt="" />}
          isLoading={isLoading}
          isError={isError}
        />
        <DashboardInfoCard
          title={t("dashboard.rewardsReceived")}
          content={data?.claimedRewardCount}
          icon={<img src={getImageUrl(giftDarkIcon)} alt="" />}
          isLoading={isLoading}
          isError={isError}
        />
      </Box>
      <Box
        sx={{
          display: "grid",
          gap: 3,
          gridTemplateColumns: { sm: "1fr 1fr" },
          gridAutoRows: "max-content",
        }}
      >
        <DashboardInfoCard
          title={t("dashboard.createdCompanies")}
          content={data?.existingCompaniesCount}
          icon={<CorporateFare color="primary" />}
          isLoading={isLoading}
          isError={isError}
        />
        <DashboardInfoCard
          title={t("dashboard.paidCompanies")}
          content={data?.activeCompaniesCount}
          icon={<CorporateFare color="primary" />}
          isLoading={isLoading}
          isError={isError}
        />
      </Box>
      <DashboardCharts root />
      <DashboardPlansUsageChart />
    </Box>
  );
};
