import { createFileRoute, redirect } from "@tanstack/react-router";
import { CompaniesPage } from "../../../pages/Companies/CompaniesPage";

export const Route = createFileRoute("/_private/companies/")({
  component: () => <CompaniesPage />,
  beforeLoad: ({ context }) => {
    const { getUserRole } = context.auth;
    const role = getUserRole();

    if (role !== "Admin") {
      throw redirect<any>({
        to: "/",
      });
    }
  },
});
