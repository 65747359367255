import {
  EmployeeDto,
  RegisterEmployeeRequest,
  UpdateEmployeeCommand,
} from "@repo/types/companyApi.types";
import { httpClient } from "../../http-client";
import { companyApiPaths } from "./paths";
import { EmployeeRole } from "../../types";

export interface RequiredEmployeeDto extends EmployeeDto {
  id: string;
}

export const getEmployeesList = async (
  tenantId: string,
  role?: EmployeeRole
) => {
  const response = await httpClient.get<RequiredEmployeeDto[]>(
    `${companyApiPaths.employees.base(tenantId)}?role=${role || "OPERATOR"}`
  );

  return response.data;
};

export const addEmployee = async (
  tenantId: string,
  { email, password }: RegisterEmployeeRequest
) => {
  const response = await httpClient.post(
    companyApiPaths.employees.base(tenantId),
    {
      email,
      password,
    }
  );

  return response.data;
};

export const editEmployee = async (
  tenantId: string,
  { id, email, newPassword }: UpdateEmployeeCommand
) => {
  const response = await httpClient.patch(
    companyApiPaths.employees.update(tenantId, id),
    {
      id,
      email,
      newPassword,
    }
  );

  return response.data;
};

export const deleteEmployee = async (tenantId: string, id: string) => {
  const response = await httpClient.delete(
    companyApiPaths.employees.delete(tenantId, id)
  );

  return response.data;
};
