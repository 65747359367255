import { createFileRoute, redirect } from "@tanstack/react-router";
import { NewReward } from "../../../pages/Rewards/NewReward";

export const Route = createFileRoute("/_private/rewards/new")({
  component: () => <NewReward />,
  beforeLoad: ({ context }) => {
    const { canCreate } = context.permissions.rewards;

    if (!canCreate) {
      throw redirect<any>({
        to: "/",
      });
    }
  },
});
