import { createFileRoute, redirect } from "@tanstack/react-router";
import { EditEmployee } from "../../../../pages/Employees/NewEmployee";
import { getEmployeesList } from "@repo/api-config/services/company";
import { cmsRoutes, getCookie } from "@repo/utils";
import { getTenantId } from "@repo/api-config";

export const Route = createFileRoute("/_private/employees/edit/$employeeId")({
  component: () => <EditEmployee />,
  beforeLoad: ({ context }) => {
    const { canUpdate } = context.permissions.employees;

    if (!canUpdate) {
      throw redirect<any>({
        to: cmsRoutes.employees.base,
      });
    }
  },
  loader: async ({ params }) => {
    const role = getCookie("loymeeUserRole");
    const tenantId = getTenantId();
    const employees = await getEmployeesList(
      tenantId!,
      role === "PendingOwner" ? "PENDINGOPERATOR" : "OPERATOR"
    );
    return employees.find((employee) => employee.id === params.employeeId);
  },
});
