import { RegisterCompanyCommand } from "@repo/types/companyApi.types";
import { createContext, useState, useContext, ReactNode } from "react";

type FormData = RegisterCompanyCommand & { acceptTerms: boolean };

interface RegisterCompanyContextType {
  formData?: FormData;
  updateFormData: (props: Partial<RegisterCompanyCommand>) => void;
  step: "BASIC" | "ADDRESS" | "FINAL";
  previousStep: () => void;
  nextStep: () => void;
}

const RegisterCompanyContext = createContext<
  RegisterCompanyContextType | undefined
>(undefined);

export const RegisterCompanyProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [formData, setFormData] = useState<FormData>();
  const [step, setStep] = useState<"BASIC" | "ADDRESS" | "FINAL">("BASIC");

  const previousStep = () => {
    setStep("BASIC");
  };

  const nextStep = () => {
    if (step === "BASIC") setStep("ADDRESS");
    if (step === "ADDRESS") setStep("FINAL");
  };

  const updateFormData = (data: { [key: string]: any }) => {
    setFormData((prevData: any) => ({
      ...prevData,
      ...data,
    }));
  };

  return (
    <RegisterCompanyContext.Provider
      value={{
        formData,
        updateFormData,
        step,
        previousStep,
        nextStep,
      }}
    >
      {children}
    </RegisterCompanyContext.Provider>
  );
};

export const useRegisterCompanyContext = (): RegisterCompanyContextType => {
  const context = useContext(RegisterCompanyContext);
  if (!context) {
    throw new Error(
      "useRegisterCompanyContext must be used within a RegisterCompanyProvider"
    );
  }
  return context;
};
