import {
  NoContent,
  PaymentMethodVmPaginatedList,
} from "@repo/types/companyApi.types";
import { httpClient } from "../../http-client";
import { companyApiPaths } from "./paths";

export interface SavePaymentMethodData {
  paymentMethodId?: string;
  paymentNonce: string;
}

export const getPaymentMethod = async (tenantId: string) => {
  const response = await httpClient.get<PaymentMethodVmPaginatedList>(
    companyApiPaths.paymentMethods.base(tenantId),
    {
      data: {
        pageNumber: 1,
        pageSize: 5,
      },
    }
  );

  return response.data.items[0] ?? null;
};

export const savePayment = async (
  tenantId: string,
  data: SavePaymentMethodData
) => {
  const response = await httpClient.post(
    companyApiPaths.paymentMethods.base(tenantId),
    data
  );

  return response.data;
};

export const changePayment = async (
  tenantId: string,
  data: SavePaymentMethodData
) => {
  const response = await httpClient.put<
    {
      paymentMethodId: string;
      paymentNonce: string;
    },
    NoContent
  >(
    companyApiPaths.paymentMethods.change(tenantId, data.paymentMethodId!),
    data
  );

  return response.statusCode;
};

export const deletePayment = async (
  tenantId: string,
  paymentMethodId: string
) => {
  const response = await httpClient.delete(
    companyApiPaths.paymentMethods.change(tenantId, paymentMethodId)
  );

  return response.data;
};
