import { httpClient } from "../../http-client";
import { companyApiPaths } from "./paths";
import { InvoiceVmPaginatedList } from "@repo/types/companyApi.types";

export const getInvoices = async (
  tenantId: string,
  params?: {
    pageNumber: number;
    pageSize: number;
  }
) => {
  const response = await httpClient.get<InvoiceVmPaginatedList>(
    companyApiPaths.invoices.base(tenantId, params?.pageNumber, params?.pageSize)
  );

  return response.data;
};
