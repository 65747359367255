import { httpClient } from "../../http-client";
import { StatisticsChartTimePeriod } from "../../types";
import { rootApiPaths } from "./paths";
import {
  RootChartStatisticsDto,
  RootNumericStatisticsDto,
} from "@repo/types/rootApi.types";

export const getStatistics = async () => {
  const response = await httpClient.get<RootNumericStatisticsDto>(
    rootApiPaths.statistics.base
  );

  return response.data;
};

export const getStatisticsChart = async (
  period: StatisticsChartTimePeriod
) => {
  const response = await httpClient.get<RootChartStatisticsDto>(
    rootApiPaths.statistics.chart(period)
  );

  return response.data;
};
