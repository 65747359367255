import { CheckCircle, Error } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import { useContext } from "react";

interface CompanyStatusProps {
  status: boolean;
}

export const CompanyStatus = ({ status }: CompanyStatusProps) => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "ui");

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 0.75 }}>
      {status ? <CheckCircle color="success" /> : <Error color="error" />}
      <Typography variant="body2">
        {t(`DataTable.columns.isActive.${status as boolean}`)}
      </Typography>
    </Box>
  );
};
