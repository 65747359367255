import { FormGroup } from "@mui/material";
import { TextInput, LoadingButton, ErrorMessage } from "@repo/ui";
import {
  emailValidator,
  loginPasswordValidator,
  requiredStringValidator,
} from "@repo/utils";
import { useLoginForm } from "./useLoginForm";
import { useContext } from "react";
import { I18nContext, useTranslation } from "@repo/i18n-config";

export const LoginForm = () => {
  const { form, isPending, errorMessage } = useLoginForm({
    isRootLogin: false,
  });
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
    >
      <FormGroup sx={{ gap: 2, mt: 1, mb: 2 }}>
        <TextInput
          form={form}
          name="tenantId"
          validators={{
            onChange: requiredStringValidator,
          }}
          label={t("Login.form.tenantId")}
        />
        <TextInput
          form={form}
          name="email"
          validators={{
            onChange: emailValidator,
          }}
          type="email"
          label={t("Login.form.email")}
        />
        <TextInput
          form={form}
          name="password"
          validators={{
            onChange: loginPasswordValidator,
          }}
          type="password"
          label={t("Login.form.password")}
        />
      </FormGroup>
      <LoadingButton isLoading={isPending} text={t("Login.form.login")} />
      <ErrorMessage errorMessage={errorMessage} />
    </form>
  );
};

export const RootLoginForm = () => {
  const { form, isPending, errorMessage } = useLoginForm({ isRootLogin: true });
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
    >
      <FormGroup sx={{ gap: 2, mt: 1, mb: 2 }}>
        <TextInput
          form={form}
          name="email"
          validators={{
            onChange: emailValidator,
          }}
          type="email"
          label={t("Login.form.email")}
        />
        <TextInput
          form={form}
          name="password"
          validators={{
            onChange: loginPasswordValidator,
          }}
          type="password"
          label={t("Login.form.password")}
        />
      </FormGroup>
      <LoadingButton
        isLoading={isPending}
        text={t("Login.form.login")}
        size="large"
      />
      <ErrorMessage errorMessage={errorMessage} />
    </form>
  );
};
