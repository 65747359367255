"use client";

import { Table, TableContainer, Paper, Box } from "@mui/material";
import {
  renderTableHeader,
  renderTableBody,
  ShowActions,
} from "./DataTableContent";

interface SimpleDataTableProps<ITEM extends { id: string | number }> {
  data?: ITEM[];
  isLoading?: boolean;
  showActions: ShowActions;
}

export const SimpleDataTable = <ITEM extends { id: string | number }>({
  data,
  isLoading = false,
  showActions = {},
}: SimpleDataTableProps<ITEM>) => {
  const columnNames = data?.length
    ? Object.keys(data[0] || {}).filter((columnName) => columnName !== "id")
    : [];

  return (
    <TableContainer component={Paper} elevation={1} variant="elevation">
      <Box sx={{ position: "relative", overflowX: "auto" }}>
        <Table>
          {renderTableHeader(columnNames, showActions)}
          {renderTableBody(data ?? [], isLoading, columnNames, showActions)}
        </Table>
      </Box>
    </TableContainer>
  );
};
