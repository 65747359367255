import { createFileRoute, redirect } from "@tanstack/react-router";
import { NewPlan } from "../../../pages/Plans/NewPlan.js";

export const Route = createFileRoute("/_private/plans/new")({
  component: () => <NewPlan />,
  beforeLoad: ({ context }) => {
    const { getUserRole } = context.auth;
    const role = getUserRole();

    if (role !== "Admin") {
      throw redirect<any>({
        to: "/",
      });
    }
  },
});
