// fallback=default, then in case of extending the lang palette we will expand the languages array
export const fallbackLng = "pl";
export const languages = [fallbackLng];
export const cookieName = "loymeeI18nLang";
export const defaultNS = "translation";

export function getOptions(
  lng?: string | undefined,
  ns: string | string[] = defaultNS
) {
  return {
    supportedLngs: languages,
    fallbackLng,
    lng,
    fallbackNS: defaultNS,
    defaultNS,
    ns,
  };
}

export function getMuiLocale(lng: string) {
  switch (lng) {
    case "pl":
      return "plPL";
    case "en":
      return "enUS";
    default:
      return "plPL";
  }
}
