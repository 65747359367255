import {
  handleAuthResponse,
  clearUserAuth,
  isAuthenticated,
} from "./authUtils";
import { signIn as signInService } from "../services/root";
import { LoginRequest } from "@repo/types/rootApi.types";
import { getCookie } from "@repo/utils";

export interface RootAuthProps {
  signIn: (props: LoginRequest) => Promise<void>;
  logout: () => Promise<void>;
  isAuthenticated: () => boolean;
  getUserRole: () => string | null;
}

export const useRootAuth = (): RootAuthProps => {
  const signIn = async ({ email, password }: LoginRequest): Promise<void> => {
    const response = await signInService({ email, password });
    return handleAuthResponse(response, {}, true);
  };

  const getUserRole = () => {
    return getCookie("loymeeUserRole");
  };

  return {
    signIn,
    logout: clearUserAuth,
    isAuthenticated,
    getUserRole,
  };
};
