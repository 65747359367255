import { Button } from "@mui/material";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import { useContext } from "react";

interface DownloadButtonProps {
  href?: string;
}

export const DownloadButton = ({ href }: DownloadButtonProps) => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "ui");

  if (href)
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        <Button>{t("DataTable.columns.downloadButton.download")}</Button>
      </a>
    );
  return (
    <Button disabled>{t("DataTable.columns.downloadButton.download")}</Button>
  );
};
