import {
  BillingProfileVm,
  CreateBillingProfileCommand,
  UpdateBillingProfileCommand,
} from "@repo/types/companyApi.types";
import { httpClient } from "../../http-client";
import { companyApiPaths } from "./paths";

export const getBillingData = async (tenantId: string) => {
  const response = await httpClient.get<BillingProfileVm | null>(
    companyApiPaths.billing.profile(tenantId)
  );

  return response.data;
};

export const createBillingData = async (
  tenantId: string,
  data: CreateBillingProfileCommand
) => {
  const response = await httpClient.post(
    companyApiPaths.billing.profile(tenantId),
    data
  );

  return response.data;
};

export const updateBillingData = async (
  tenantId: string,
  data: UpdateBillingProfileCommand
) => {
  const response = await httpClient.put(
    companyApiPaths.billing.profile(tenantId),
    data
  );

  return response.data;
};
