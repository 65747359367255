"use client"

import * as React from "react";

interface I18nContextProps {
  lang: string;
}

const I18nContext = React.createContext<I18nContextProps>({
  lang: "",
});

export { I18nContext };
