import { createFileRoute } from "@tanstack/react-router";
import DashboardPage from "../../pages/Dashboard/DashboardPage";

const Dashboard = () => {
  return <DashboardPage />;
};

export const Route = createFileRoute("/_private/")({
  component: Dashboard,
});
