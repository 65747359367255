import { createFileRoute, redirect } from "@tanstack/react-router";
import { NewEmployee } from "../../../pages/Employees/NewEmployee";

export const Route = createFileRoute("/_private/employees/new")({
  component: () => <NewEmployee />,
  beforeLoad: ({ context }) => {
    const { canCreate } = context.permissions.employees;

    if (!canCreate) {
      throw redirect<any>({
        to: "/",
      });
    }
  },
});
