import {
  AccessTokenResponse,
  LoginRequest,
} from "@repo/types/customerApi.types";
import { rootApiPaths } from "./paths";
import { baseApiUrl, httpClient } from "../../http-client";
import { UserInfoResponse } from "@repo/types/rootApi.types";
import axios from "axios";

interface RootRefreshTokenRequest {
  refreshToken: string;
}

export const signIn = async ({ email, password }: LoginRequest) => {
  const response = await axios.post<AccessTokenResponse>(
    baseApiUrl + rootApiPaths.auth.login.slice(1),
    {
      email,
      password,
    }
  );
  return response.data;
};

export const refreshToken = async ({
  refreshToken,
}: RootRefreshTokenRequest) => {
  const response = await axios.post<AccessTokenResponse>(
    baseApiUrl + rootApiPaths.auth.refresh,
    {
      refreshToken,
    }
  );
  return response.data;
};

export const getUserInfo = async () => {
  const response = await httpClient.get<UserInfoResponse>(
    rootApiPaths.auth.info
  );

  return response.data;
};
