import { Box } from "@mui/material";
import {
  DashboardCharts,
  DashboardInfoCard,
  giftDarkIcon,
  qrDarkIcon,
  usersDarkIcon,
} from "@repo/ui";
import { getImageUrl } from "@repo/utils";
import { DashboardSidebar } from "./DashboardSidebar";
import { Loyalty } from "@mui/icons-material";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { getStatistics } from "@repo/api-config/services/company";
import { getTenantId } from "@repo/api-config";

export const DashboardCompanyLayout = () => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");

  const tenantId = getTenantId();
  const { data, isLoading, isError } = useQuery({
    queryFn: () => getStatistics(tenantId!),
    queryKey: ["DashboardStatistics"],
    enabled: !!tenantId,
  });

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: { lg: "auto 280px" },
        gap: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <Box
          sx={{
            display: "grid",
            gap: 3,
            gridTemplateColumns: { sm: "1fr 1fr", xl: "1fr 1fr 1fr 1fr" },
            gridAutoRows: "max-content",
          }}
        >
          <DashboardInfoCard
            title={t("dashboard.scanCount")}
            content={data?.purchaseScanCount}
            icon={<img src={getImageUrl(qrDarkIcon)} alt="" />}
            isLoading={isLoading}
            isError={isError}
          />
          <DashboardInfoCard
            title={t("dashboard.customerCount")}
            content={data?.customerCount}
            icon={<img src={getImageUrl(usersDarkIcon)} alt="" />}
            isLoading={isLoading}
            isError={isError}
          />
          <DashboardInfoCard
            title={t("dashboard.rewardsGranted")}
            content={data?.claimedRewardsCount}
            icon={<img src={getImageUrl(giftDarkIcon)} alt="" />}
            isLoading={isLoading}
            isError={isError}
          />
          <DashboardInfoCard
            title={t("dashboard.pointsSum")}
            content={data?.purchasePointsSum}
            icon={<Loyalty color="primary" />}
            isLoading={isLoading}
            isError={isError}
          />
        </Box>
        <DashboardCharts />
      </Box>
      <DashboardSidebar />
    </Box>
  );
};
