"use client";

import {
  Table,
  TableContainer,
  Paper,
  Button,
  Box,
  TablePagination,
  TextField,
} from "@mui/material";
import { FileDownloadOutlined, FilterAlt, Search } from "@mui/icons-material";
import {
  renderTableHeader,
  renderTableBody,
  ShowActions,
} from "./DataTableContent";
import { useContext } from "react";
import { I18nContext, useTranslation } from "@repo/i18n-config";

interface TempPagedListModel<ITEM> {
  items: ITEM[];
  totalPages: number;
  totalCount: number;
  pageNumber: number;
  pageSize: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

interface ShowOptions {
  search?: boolean;
  export?: boolean;
  filter?: boolean;
}

interface DataTableProps<ITEM extends { id: string | number }> {
  data?: TempPagedListModel<ITEM>;
  isFetching?: boolean;
  pageSize?: RowsPerPageOption;
  handlePageChange?: (page: number) => void;
  handlePageSizeChange?: (size: RowsPerPageOption) => void;
  showOptions?: ShowOptions;
  showActions?: ShowActions;
}

const rowsPerPageOptions = [5, 10, 20, 50, 100] as const;
export type RowsPerPageOption = (typeof rowsPerPageOptions)[number];

export const DataTable = <ITEM extends { id: string | number }>({
  data,
  isFetching = false,
  pageSize = 10,
  handlePageChange = () => {},
  handlePageSizeChange = () => {},
  showOptions = {},
  showActions = {},
}: DataTableProps<ITEM>) => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "ui");

  const {
    search = false,
    export: exportOption = false,
    filter = false,
  } = showOptions;

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handlePageChange(1);
    handlePageSizeChange(parseInt(event.target.value, 10) as RowsPerPageOption);
  };

  const columnNames = data?.items.length
    ? Object.keys(data.items[0] || {}).filter(
        (columnName) => columnName !== "id"
      )
    : [];

  return (
    <TableContainer component={Paper} elevation={1} variant="elevation">
      {(search || exportOption || filter) && (
        <Box
          sx={{
            display: "flex",
            gap: 2,
            p: 2,
            backgroundColor: (theme) => theme.palette.grey[900],
          }}
        >
          {search && (
            <TextField
              name="search"
              placeholder={`${t("DataTable.search")}...`}
              variant="standard"
              InputProps={{ startAdornment: <Search sx={{ mr: 1 }} /> }}
            />
          )}
          {exportOption && (
            <Button
              variant="text"
              size="small"
              startIcon={<FileDownloadOutlined />}
              disabled={!data?.items?.length}
            >
              {t("DataTable.export")}
            </Button>
          )}
          {filter && (
            <Button
              variant="text"
              size="small"
              startIcon={<FilterAlt />}
              disabled={!data?.items?.length}
            >
              {t("DataTable.filter")}
            </Button>
          )}
        </Box>
      )}
      <Box sx={{ position: "relative", overflowX: "auto" }}>
        <Table>
          {renderTableHeader(columnNames, showActions)}
          {renderTableBody(
            data?.items ?? [],
            isFetching,
            columnNames,
            showActions
          )}
        </Table>
      </Box>
      {data && data.items.length ? (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={data.totalCount}
          rowsPerPage={pageSize}
          page={data.pageNumber - 1}
          onPageChange={(_, page) => handlePageChange(page + 1)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}
    </TableContainer>
  );
};
