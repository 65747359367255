import axios from "axios";
import { refreshToken as customerRefreshTokenService } from "./services/customer";
import { refreshToken as companyRefreshTokenService } from "./services/company";
import { refreshToken as rootRefreshTokenService } from "./services/root";
import { clearUserAuth, getStoredRefreshToken, getStoredToken } from "./";
import { getCookie, setCookie } from "@repo/utils";
import { companyApiPaths } from "./services/company/paths";
import { customerApiPaths } from "./services/customer/paths";

interface ImportMetaEnv {
  readonly VITE_API_BASE_URL: string;
}
interface ImportMeta {
  readonly env: ImportMetaEnv;
}

export const baseApiUrl =
  (import.meta as unknown as ImportMeta).env?.VITE_API_BASE_URL ??
  process.env.NEXT_PUBLIC_API_BASE_URL;

// TODO optimize
export const httpClient = axios.create({
  baseURL: baseApiUrl,
});

let isRefreshing = false;
let failedQueue: Array<{
  resolve: (token: string) => void;
  reject: (error: any) => void;
}> = [];

const processQueue = (error: any, token: string | null = null): void => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token as string);
    }
  });

  failedQueue = [];
};

httpClient.interceptors.response.use(
  (res) => res,
  (err) => {
    const originalReq = err.config;
    const refreshToken = getStoredRefreshToken();
    const tenantId = getCookie("loymeeTenantId") || "";
    const status = err?.response?.status;

    if (
      (status === 401 ||
        originalReq.url === companyApiPaths.auth.info(tenantId) ||
        originalReq.url === customerApiPaths.auth.info) &&
      !originalReq._retry &&
      originalReq.url !== companyApiPaths.auth.login(tenantId) &&
      originalReq.url !== customerApiPaths.auth.login &&
      originalReq.url !== customerApiPaths.auth.refresh &&
      originalReq.url !== companyApiPaths.auth.refresh(tenantId)
    ) {
      if (refreshToken) {
        if (isRefreshing) {
          return new Promise<string>((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalReq.headers["Authorization"] = "Bearer " + token;
              return httpClient(originalReq);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }

        originalReq._retry = true;
        isRefreshing = true;

        return new Promise((resolve, reject) => {
          (async () => {
            try {
              let newAccessToken: string | undefined;
              let tokenExpiresIn: number | undefined;
              let newRefreshToken: string | undefined;

              if (tenantId) {
                const response = await companyRefreshTokenService({
                  refreshToken: refreshToken,
                  tenantId,
                });
                newAccessToken = response?.accessToken;
                tokenExpiresIn = response?.expiresIn;
                newRefreshToken = response?.refreshToken;
              } else if (
                (import.meta as unknown as ImportMeta).env?.VITE_API_BASE_URL &&
                !tenantId
              ) {
                const response = await rootRefreshTokenService({
                  refreshToken: refreshToken,
                });
                newAccessToken = response?.accessToken;
                tokenExpiresIn = response?.expiresIn;
                newRefreshToken = response?.refreshToken;
              } else {
                const response = await customerRefreshTokenService({
                  refreshToken: refreshToken,
                });
                newAccessToken = response?.accessToken;
                tokenExpiresIn = response?.expiresIn;
                newRefreshToken = response?.refreshToken;
              }

              if (!newAccessToken) {
                handleLogout();
                processQueue(err, null);
                return reject(err);
              }

              setCookie(
                "loymeeAccessToken",
                newAccessToken,
                tokenExpiresIn! * 1000
              );
              setCookie(
                "loymeeRefreshToken",
                newRefreshToken!,
                14 * 24 * 60 * 60 * 1000
              );
              if (tenantId)
                setCookie("loymeeTenantId", tenantId, 14 * 24 * 60 * 60 * 1000);
              const userRole = getCookie("loymeeUserRole");
              if (userRole)
                setCookie("loymeeUserRole", userRole, 14 * 24 * 60 * 60 * 1000);

              originalReq.headers["Authorization"] = `Bearer ${newAccessToken}`;
              processQueue(null, newAccessToken);
              resolve(httpClient(originalReq));
            } catch (refreshError) {
              handleLogout();
              processQueue(refreshError, null);
              reject(refreshError);
            } finally {
              isRefreshing = false;
            }
          })();
        });
      } else {
        handleLogout();
        return Promise.reject(err);
      }
    }

    return Promise.reject(err);
  }
);

const handleLogout = async () => {
  const accessToken = getCookie("loymeeAccessToken") || "";
  const refreshToken = getStoredRefreshToken();
  if (accessToken || refreshToken) {
    await clearUserAuth();
    window.location.reload();
  }
};

httpClient.interceptors.request.use(async (request) => {
  const isServer = typeof window === "undefined";
  let token;

  if (isServer) {
    const getServerToken = async () => {
      const { cookies } = await require("next/headers");
      return cookies().get("loymeeAccessToken")?.value;
    };

    token = await getServerToken();
  } else {
    token = getStoredToken();
  }

  if (token) {
    request.headers["Authorization"] = `Bearer ${token}`;
  }

  return request;
});
