import { createFileRoute } from "@tanstack/react-router";
import { ResetPasswordPage } from "../../../pages/ResetPassword";

export interface ResetPasswordRouteParams {
  email?: string;
  code?: string;
  identifier?: string;
}

export const Route = createFileRoute("/_public/reset-password/")({
  component: ResetPasswordPage,
  validateSearch: (
    search: Record<string, string>
  ): ResetPasswordRouteParams => {
    return {
      email: search.email,
      code: search.code,
      identifier: search.identifier,
    };
  },
});
