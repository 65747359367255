import { Add } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent } from "@mui/material";
import { getTenantId } from "@repo/api-config";
import {
  deleteEmployee,
  getEmployeesList,
  getFlags,
} from "@repo/api-config/services/company";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import { ConfirmModal, LinkWrapper, SimpleDataTable } from "@repo/ui";
import { cmsRoutes, getCookie, useNotificationsContext } from "@repo/utils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Link, useRouter } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { useContext, useEffect, useState } from "react";
import { DeletedItem } from "../../@types";

export const EmployeesPage = () => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");
  const tenantId = getTenantId();
  const role = getCookie("loymeeUserRole");
  const { state } = useRouter();
  const [pendingOwnerModalOpen, setPendingOwnerModalOpen] = useState(false);

  useEffect(() => {
    if (state.location.searchStr.includes("?pending=true")) {
      setPendingOwnerModalOpen(true);
    }
  }, []);

  const { showNotification } = useNotificationsContext();
  const {
    data: employeesList,
    isLoading,
    refetch: refetchEmployeesList,
  } = useQuery({
    queryFn: () =>
      getEmployeesList(
        tenantId!,
        role === "PendingOwner" ? "PENDINGOPERATOR" : "OPERATOR"
      ),
    queryKey: ["employees"],
    enabled: !!tenantId,
    refetchOnMount: true,
  });

  const { data: flags, refetch: refetchFlags } = useQuery({
    queryKey: ["flags"],
    queryFn: () => getFlags(tenantId!),
    refetchOnMount: true,
  });

  const [deletedItem, setDeletedItem] = useState<DeletedItem>(null);
  const {
    mutate: deleteEmployeeMutation,
    isPending: isDeleteEmployeeMutationPending,
  } = useMutation<any, AxiosError, any>({
    mutationFn: (id) => deleteEmployee(tenantId!, id),
    onSuccess: () => {
      showNotification(t("Employees.employeeDeleted"));
      setDeletedItem(null);
      refetchEmployeesList();
      refetchFlags();
    },
    onError: () => {
      showNotification(t("Employees.employeeDeleteFail"), {
        type: "error",
      });
      setDeletedItem(null);
    },
  });

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          mt: -5,
          mb: 3,
        }}
      >
        <Button
          component={Link}
          to={cmsRoutes.employees.new}
          size="large"
          sx={{ pl: 2 }}
          disabled={
            flags?.employeeLimitReached ??
            (employeesList && employeesList.length > 1)
          }
        >
          <Add sx={{ mr: 1 }} />
          {t("Employees.addEmployee")}
        </Button>
      </Box>
      <SimpleDataTable
        data={employeesList}
        isLoading={isLoading}
        showActions={{
          handleEdit: true,
          handleDelete: (id) =>
            setDeletedItem({
              id,
              name: employeesList?.find((i) => i.id === id)?.email!,
            }),
          isDeleteMutationPending: isDeleteEmployeeMutationPending,
        }}
      />
      <ConfirmModal
        open={!!deletedItem?.id}
        handleNo={() => setDeletedItem({ ...deletedItem!, id: "" })}
        handleYes={() => {
          setDeletedItem({ ...deletedItem!, id: "" });
          deleteEmployeeMutation(deletedItem!.id);
        }}
        text={t("Employees.employeeDeleteConfirm", { name: deletedItem?.name })}
      />
      <Dialog open={pendingOwnerModalOpen} fullWidth maxWidth="sm">
        <DialogContent>
          {t("Employees.pendingOwnerModal.content")}
          <Box sx={{ mt: 2, display: "flex", justifyContent: "end", gap: 1 }}>
            <LinkWrapper to={cmsRoutes.home.base}>
              <Button onClick={() => setPendingOwnerModalOpen(false)}>
                {t("Employees.pendingOwnerModal.continue")}
              </Button>
            </LinkWrapper>
            <Button
              variant="text"
              onClick={() => setPendingOwnerModalOpen(false)}
            >
              {t("Employees.pendingOwnerModal.next")}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
