import { Box } from "@mui/material";
import Rewards from "./Rewards";
import Points from "./Points";

const RewardsPage = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
      <Points />
      <Rewards />

      {/* Redundant feature: */}
      {/* <Activity /> */}
    </Box>
  );
};

export default RewardsPage;
