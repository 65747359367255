"use client";

import { ButtonProps, Button as MuiButton } from "@mui/material";

export const ErrorButton = ({ ...props }: ButtonProps) => {
  return (
    <MuiButton
      {...props}
      color="error"
      sx={{
        "&:hover": {
          backgroundColor: (theme) => theme.palette.error.dark,
        },
        "&:active": {
          backgroundColor: (theme) => theme.palette.error.dark,
        },
      }}
    >
      {props.children}
    </MuiButton>
  );
};
