import { Box } from "@mui/material";
import { getTenantId } from "@repo/api-config";
import { getInvoices } from "@repo/api-config/services/company";
import { DataTable, RowsPerPageOption } from "@repo/ui";
import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";

export const InvoicesPage = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState<RowsPerPageOption>(10);
  const tenantId = getTenantId();

  const { data, isLoading } = useQuery({
    queryFn: () => getInvoices(tenantId!, { pageNumber, pageSize }),
    queryKey: ["Invoices", pageNumber, pageSize],
    enabled: !!tenantId,
  });

  const invoicesList = useMemo(() => {
    if (data)
      return {
        ...data,
        items: data.items.map((obj) => {
          const { currency, ...rest } = obj;
          return { ...rest };
        }),
      };
  }, [data]);

  return (
    <Box>
      <DataTable
        handlePageChange={setPageNumber}
        handlePageSizeChange={setPageSize}
        pageSize={pageSize}
        isFetching={isLoading}
        data={invoicesList}
      />
    </Box>
  );
};
