"use client";

import { ButtonProps, CircularProgress } from "@mui/material";
import { Button } from "./Button";
import { useMemo } from "react";

interface LoadingButtonProps {
  isLoading: boolean;
  text: string;
  size?: ButtonProps["size"];
  type?: ButtonProps["type"];
  onClick?: ButtonProps["onClick"];
  sx?: ButtonProps["sx"];
  fullWidth?: ButtonProps["fullWidth"];
  variant?: ButtonProps["variant"];
  disabled?: ButtonProps["disabled"];
}

export const LoadingButton = ({
  isLoading,
  text,
  size,
  type = "submit",
  onClick,
  sx,
  fullWidth = true,
  variant = "contained",
  disabled = false,
}: LoadingButtonProps) => {
  const circularProgressSize = useMemo(() => {
    if (size === "small") return 14;
    if (size === "large") return 18;
    return 16;
  }, [size]);

  return (
    <Button
      type={type}
      variant={variant}
      fullWidth={fullWidth}
      disabled={isLoading || disabled}
      onClick={onClick}
      sx={sx}
      size={size}
    >
      {isLoading ? (
        <CircularProgress
          size={circularProgressSize}
          sx={{
            mr: 1,
            color: (theme) =>
              variant === "text"
                ? theme.palette.primary.contrast
                : theme.palette.primary.main,
          }}
        />
      ) : null}
      {text}
    </Button>
  );
};
