"use client";

import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { PackageLevel } from "./PackageLevel";
import { useContext, useEffect } from "react";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import { LinkWrapper } from "../LinkWrapper";
import { cmsRoutes, useServerErrorFormatter } from "@repo/utils";
import { useQuery } from "@tanstack/react-query";
import { getCurrentSubscription } from "@repo/api-config/services/company";
import { getTenantId } from "@repo/api-config";
import { AxiosError } from "axios";
import { ErrorMessage } from "../ErrorMessage";

export const DashboardPlanInfo = () => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");
  const tenantId = getTenantId();
  const { errorMessage, setError } = useServerErrorFormatter();

  const { data, isLoading, error } = useQuery({
    queryKey: ["currentSubscription"],
    queryFn: () => getCurrentSubscription(tenantId!),
  });

  useEffect(() => {
    if (error) setError(error as AxiosError);
  }, [error]);

  if (isLoading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );

  if (errorMessage && !data)
    return <ErrorMessage errorMessage={errorMessage} />;

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
        }}
      >
        <PackageLevel level={3} />
        <Typography variant="h6" fontWeight={700}>
          {data?.ratePlan?.name}
        </Typography>
      </Box>
      <LinkWrapper to={cmsRoutes.settings.base}>
        <Button color="secondary" fullWidth sx={{ mt: 2 }}>
          {t("dashboard.sidebar.changePlan")}
        </Button>
      </LinkWrapper>
    </Box>
  );
};
