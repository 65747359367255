import { httpClient } from "../../http-client";
import { companyApiPaths } from "./paths";
import {
  SendEmailToCompanySubscribersCommand,
} from "@repo/types/companyApi.types";

export const sendMailNotification = async (
  tenantId: string,
  data: SendEmailToCompanySubscribersCommand
) => {
  const response = await httpClient.post(
    companyApiPaths.emails.subscribers(tenantId),
    data
  );

  return response.data;
};
