import {
  EmailConfirmationRequest,
  ResendConfirmationEmailFormValues,
  confirmEmail,
  resendConfirmationEmail,
} from "@repo/api-config/services/company";
import { Card, LinkWrapper, LoadingButton, Logo, TextInput } from "@repo/ui";
import {
  cmsRoutes,
  emailValidator,
  getCookie,
  requiredStringValidator,
} from "@repo/utils";
import { useMutation } from "@tanstack/react-query";
import { useSearch } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { useContext, useEffect, useState } from "react";
import { ConfirmEmailRouteParams } from "../../routes/_public/confirm-email";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import { Box, Button, FormGroup, Typography } from "@mui/material";
import { useForm } from "@tanstack/react-form";
import { valibotValidator } from "@tanstack/valibot-form-adapter";

export function ConfirmEmailPage() {
  const { identifier, code, changedEmail }: ConfirmEmailRouteParams = useSearch(
    {
      strict: false,
    }
  );
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");
  const [status, setStatus] = useState<
    "pending" | "confirmed" | "error" | "newLink"
  >("pending");

  const { mutate: resendEmailMutation, isPending: resendPending } = useMutation<
    void,
    AxiosError,
    ResendConfirmationEmailFormValues
  >({
    mutationFn: resendConfirmationEmail,
    onSuccess: () => {
      setStatus("newLink");
    },
    onError: () => {
      setStatus("newLink");
    },
  });

  const resendEmailForm = useForm<ResendConfirmationEmailFormValues, any>({
    defaultValues: {
      email: "",
      tenantId: "",
    },
    onSubmit: async ({ value }) => {
      resendEmailMutation(value);
    },
    validatorAdapter: valibotValidator(),
  });

  const { mutate: confirmationMutation } = useMutation<
    void,
    AxiosError,
    EmailConfirmationRequest
  >({
    mutationFn: confirmEmail,
    onSuccess: () => {
      setStatus("confirmed");
    },
    onError: () => {
      setStatus("error");
    },
  });

  useEffect(() => {
    if (identifier && code) {
      confirmationMutation({
        code,
        tenantId: identifier,
        changedEmail,
      });
    }
  }, []);

  const isLoggedIn =
    getCookie("loymeeAccessToken") || getCookie("loymeeRefreshToken");

  return (
    <Card fullscreen>
      {status !== "pending" && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: 3,
          }}
        >
          <Logo height={50} theme="dark" />
        </Box>
      )}
      {status === "newLink" && (
        <>
          <Typography variant="h5" fontWeight={700}>
            {t("confirmEmail.newLink.header")}
          </Typography>
          <Typography>{t("confirmEmail.newLink.description")}</Typography>
          <LinkWrapper to={cmsRoutes.login.base}>
            <Button fullWidth>{t("confirmEmail.newLink.goBack")}</Button>
          </LinkWrapper>
        </>
      )}
      {status === "error" && (
        <>
          <Typography variant="h5" fontWeight={700}>
            {t("confirmEmail.error.header")}
          </Typography>
          <Typography>
            {t(
              `confirmEmail.error.description.${!!changedEmail ? "withEmail" : "withoutEmail"}`
            )}
          </Typography>
          {!changedEmail ? (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                resendEmailForm.handleSubmit();
              }}
            >
              <FormGroup sx={{ gap: 2, mt: 1, mb: 2 }}>
                <TextInput
                  form={resendEmailForm}
                  name="tenantId"
                  validators={{
                    onChange: requiredStringValidator,
                  }}
                  type="string"
                  label={t("confirmEmail.error.tenantId")}
                  required
                />
                <TextInput
                  form={resendEmailForm}
                  name="email"
                  validators={{
                    onChange: emailValidator,
                  }}
                  type="email"
                  label={t("confirmEmail.error.email")}
                  required
                />
              </FormGroup>
              <LoadingButton
                isLoading={resendPending}
                text={t("confirmEmail.error.send")}
                size="large"
              />
            </form>
          ) : (
            <LinkWrapper to={cmsRoutes.login.base}>
              <Button fullWidth>{t("confirmEmail.error.goBack")}</Button>
            </LinkWrapper>
          )}
        </>
      )}
      {status === "confirmed" && (
        <>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h5" sx={{ mb: 1 }}>
              {t("confirmEmail.confirmed.header")}
            </Typography>
            <Typography color={(theme) => theme.palette.text.secondary}>
              {t("confirmEmail.confirmed.description")}
            </Typography>
          </Box>
          <LinkWrapper
            to={isLoggedIn ? cmsRoutes.home.base : cmsRoutes.login.base}
          >
            <Button fullWidth size="large">
              {t("confirmEmail.confirmed.login")}
            </Button>
          </LinkWrapper>
        </>
      )}
    </Card>
  );
}
