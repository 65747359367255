import { createFileRoute, redirect } from "@tanstack/react-router";
import { LocationsPage } from "../../../pages/Locations/LocationsPage";
import { APIProvider as GoogleMapsAPIProvider } from "@vis.gl/react-google-maps";
import { MapPlacesProvider } from "@repo/ui";

export const Route = createFileRoute("/_private/locations/")({
  component: () => (
    <GoogleMapsAPIProvider
      apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY!}
      libraries={["places", "marker", "geocoding"]}
    >
      <MapPlacesProvider>
        <LocationsPage />
      </MapPlacesProvider>
    </GoogleMapsAPIProvider>
  ),
  beforeLoad: ({ context }) => {
    const { canRead } = context.permissions.locations;

    if (!canRead) {
      throw redirect<any>({
        to: "/",
      });
    }
  },
});
