"use client";

import {
  Box,
  FormGroup,
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import {
  DeepKeys,
  FieldApi,
  useForm,
  FieldApiOptions,
} from "@tanstack/react-form";
import { FieldInfo } from "../FieldInfo";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

type TextInputProps<T> = {
  form: ReturnType<typeof useForm<T, any>>;
  name: DeepKeys<T>;
  handleFieldChange?: (
    value: string,
    field: FieldApi<any, any, any, any, any>
  ) => void;
  required?: boolean;
  validators?: FieldApiOptions<any, any, any, any, any>["validators"];
  preserveValue?: boolean;
  charLimit?: number;
  numberType?: "float" | "int";
} & TextFieldProps;

export const TextInput = <T,>(props: TextInputProps<T>) => {
  const [revealPassword, setRevealPassword] = useState(false);
  const {
    validators,
    preserveValue,
    handleFieldChange,
    type,
    charLimit,
    ...fieldProps
  } = props;
  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: FieldApi<any, any, any, any, any>
  ): void => {
    handleFieldChange
      ? handleFieldChange(e.target.value, field)
      : field.handleChange(
          type === "number"
            ? e.target.value
              ? props.numberType === "float"
                ? Math.round(parseFloat(e.target.value) * 100) / 100
                : parseInt(e.target.value)
              : ""
            : e.target.value
        );
  };

  return (
    <props.form.Field
      name={props.name}
      preserveValue={preserveValue}
      children={(field) => (
        <FormGroup style={{ width: props.fullWidth ? "100%" : "" }}>
          <TextField
            value={field.state.value}
            onChange={(e) => handleChange(e, field)}
            type={type === "password" && revealPassword ? "text" : type}
            InputProps={{
              endAdornment: type === "password" && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setRevealPassword(!revealPassword)}
                    edge="end"
                  >
                    {revealPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...fieldProps}
          />
          <Box
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "space-between",
            }}
          >
            <FieldInfo field={field} />
            {charLimit && (
              <Typography
                variant="body2"
                sx={{
                  textAlign: "right",
                  marginLeft: "auto",
                }}
              >
                {field.state.value?.length ?? 0}/{charLimit}
              </Typography>
            )}
          </Box>
        </FormGroup>
      )}
      validators={validators}
    />
  );
};
