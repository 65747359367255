import { httpClient } from "../../http-client";
import { PaginationParams } from "../../types";
import { rootApiPaths } from "./paths";
import { PlanDto, PlanDtoPaginatedList } from "@repo/types/rootApi.types";

export const getPlansList = async (params: PaginationParams) => {
  const response = await httpClient.get<PlanDtoPaginatedList>(
    rootApiPaths.plans.base(
      params.pageParam.pageNumber,
      params.pageParam.pageSize
    )
  );

  return response.data;
};

export type CreatePlanRequest = Pick<
  PlanDto,
  | "name"
  | "pricePerMonth"
  | "pricePerYear"
  | "operatorsLimit"
  | "rewardsLimit"
  | "accessibility"
>;

export const createPlan = async (plan: CreatePlanRequest) => {
  const response = await httpClient.post(rootApiPaths.plans.create, plan);

  return response.data;
};

export const deletePlan = async (id: number) => {
  const response = await httpClient.delete(rootApiPaths.plans.delete(id));

  return response.data;
};
