import { createFileRoute } from "@tanstack/react-router";
import { ConfirmEmailPage } from "../../../pages/ConfirmEmail/ConfirmEmailPage";

export interface ConfirmEmailRouteParams {
  identifier?: string;
  code?: string;
  changedEmail?: string;
}

export const Route = createFileRoute("/_public/confirm-email/")({
  component: ConfirmEmailPage,
  validateSearch: (search: Record<string, string>): ConfirmEmailRouteParams => {
    return {
      identifier: search.identifier,
      code: search.code,
      changedEmail: search.changedEmail,
    };
  },
});
