import { createFileRoute, redirect } from "@tanstack/react-router";
import DashboardTemplate from "../../templates/DashboardTemplate.js";
import { cmsRoutes, webAppRoutes } from "@repo/utils";
import { clearUserAuth } from "@repo/api-config";

export const Route = createFileRoute("/_private")({
  component: DashboardTemplate,
  beforeLoad: ({ context }) => {
    const { isAuthenticated, getUserRole } = context.auth;

    if (getUserRole() === "Operator") {
      window.location.href = `${import.meta.env.VITE_WEBAPP_URL}/${webAppRoutes.employee.scan}`;
    }

    if (isAuthenticated() && !getUserRole()) {
      window.location.href = import.meta.env.VITE_WEBAPP_URL;
    }

    if (!isAuthenticated()) {
      const redirectUrl =
        getUserRole() === "Admin"
          ? cmsRoutes.login.admin
          : cmsRoutes.login.base;

      clearUserAuth();
      throw redirect({
        to: redirectUrl,
      });
    }
  },
});
