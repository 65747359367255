import { createFileRoute, redirect } from "@tanstack/react-router";
import { ForgotPasswordPage } from "../../../pages/ForgotPassword";

export const Route = createFileRoute("/_public/forgot-password/")({
  component: ForgotPasswordPage,
  beforeLoad: ({ context }) => {
    const { isAuthenticated } = context.auth;

    if (isAuthenticated()) {
      throw redirect<any>({
        to: "/",
      });
    }
  },
});
