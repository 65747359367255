import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { getTenantId } from "@repo/api-config";
import {
  buySubscription,
  cancelCancellation,
  cancelSubscription,
  getBillingData,
  getCurrentSubscription,
  getPaymentMethod,
} from "@repo/api-config/services/company";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import {
  CreateSubscriptionCommand,
  HttpValidationProblemDetails,
  PlanPublicDto,
  SubscriptionFrequency,
  SubscriptionState,
  SubscriptionVm,
} from "@repo/types/companyApi.types";
import { Card, ErrorButton, LinkWrapper } from "@repo/ui";
import {
  cmsRoutes,
  getCookie,
  useNotificationsContext,
  useServerErrorFormatter,
} from "@repo/utils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useContext, useMemo, useState } from "react";
import { PlanVerification } from "./PlanVerification";

interface StatusBadgeProps {
  data: SubscriptionVm;
}
const StatusBadge = ({ data }: StatusBadgeProps) => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");

  const chipColor = useMemo(() => {
    if (data.state === SubscriptionState.Cancelled || data.cancelAtPeriodEnd)
      return "warning";
    if (
      data.state === SubscriptionState.AwaitingPayment ||
      data.state === SubscriptionState.Failed ||
      data.state === SubscriptionState.Expired
    )
      return "error";
    if (
      data.state === SubscriptionState.Trial ||
      data.state === SubscriptionState.Paid
    )
      return "primary";
  }, [data]);

  const dateText = useMemo(() => {
    if (data.state === SubscriptionState.Cancelled || data.cancelAtPeriodEnd)
      return t("Rewards.subscriptionOptions.ends");
    return t("Rewards.subscriptionOptions.nextPayment");
  }, [data]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "end",
        gap: 0.5,
      }}
    >
      <Chip
        label={
          data.cancelAtPeriodEnd && data.state !== SubscriptionState.Cancelled
            ? t("Rewards.subscriptionOptions.forCancel")
            : t(`Rewards.subscriptionOptions.subscriptionState.${data.state}`)
        }
        color={chipColor}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
        }}
      >
        <Typography variant="caption">
          {t(`Rewards.subscriptionOptions.frequency.${data.frequency}`)}
        </Typography>

        {data.state === SubscriptionState.Trial && data.trialEnd && (
          <Typography variant="caption">
            {t("Rewards.subscriptionOptions.trialTo")}{" "}
            {new Date(data.trialEnd).toLocaleString(lang, {
              dateStyle: "short",
            })}
          </Typography>
        )}

        {data.currentPeriodEnd &&
          data.state !== SubscriptionState.Cancelled && (
            <Typography variant="caption">
              {dateText}{" "}
              {new Date(data.currentPeriodEnd).toLocaleString(lang, {
                dateStyle: "short",
              })}
            </Typography>
          )}

        {data.canceledAt && data.state === SubscriptionState.Cancelled && (
          <Typography variant="caption">
            {dateText}{" "}
            {new Date(data.canceledAt).toLocaleString(lang, {
              dateStyle: "short",
            })}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

interface SubscriptionCardProps {
  item: PlanPublicDto;
}

interface SubModalData {
  plan: PlanPublicDto;
  frequency: SubscriptionFrequency;
}

export const SubscriptionCard = ({ item }: SubscriptionCardProps) => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");
  const { t: commonT } = useTranslation(lang, "common");
  const tenantId = getTenantId();
  const { showNotification } = useNotificationsContext();
  const { formatErrorMessage } = useServerErrorFormatter();
  const isPendingOwner = getCookie("loymeeUserRole") === "PendingOwner";
  const [pendingOwnerModalOpen, setPendingOwnerModalOpen] = useState(false);

  const { data: currentSubscription, refetch: refetchCurrentSubscription } =
    useQuery({
      queryKey: ["currentSubscription"],
      queryFn: () => getCurrentSubscription(tenantId!),
    });

  const { data: billingData } = useQuery({
    queryFn: () => getBillingData(tenantId!),
    queryKey: ["BillingData"],
  });

  const { data: paymentData } = useQuery({
    queryFn: () => getPaymentMethod(tenantId!),
    queryKey: ["PaymentMethod"],
  });

  const isCurrentSubscription = useMemo(
    () => currentSubscription?.ratePlan?.id === item.id,
    [currentSubscription, item]
  );

  const { mutate: subscribe, isPending: buyPending } = useMutation<
    void,
    AxiosError,
    CreateSubscriptionCommand
  >({
    mutationFn: (values) => {
      if (!billingData || !paymentData) {
        showNotification(
          commonT("errorCodeMessages.billing-profile-not-configured"),
          {
            type: "error",
          }
        );
        return Promise.reject();
      } else return buySubscription(values, tenantId!);
    },
    onSuccess: () => {
      showNotification(t("Rewards.subscriptionOptions.subscriptionActivated"));
      refetchCurrentSubscription();
      isPendingOwner && setPendingOwnerModalOpen(true);
    },
    onError: (error) =>
      showNotification(
        formatErrorMessage(error as AxiosError<HttpValidationProblemDetails>),
        {
          type: "error",
        }
      ),
  });

  const { mutate: cancelSubscriptionMutation, isPending: cancelPending } =
    useMutation<void, AxiosError, void>({
      mutationFn: () => cancelSubscription(currentSubscription!.id, tenantId!),
      onSuccess: () => {
        showNotification(t("Rewards.subscriptionOptions.subscriptionCanceled"));
        setTimeout(() => {
          refetchCurrentSubscription();
        }, 1000);
      },
      onError: (error) =>
        showNotification(
          formatErrorMessage(error as AxiosError<HttpValidationProblemDetails>),
          {
            type: "error",
          }
        ),
    });

  const {
    mutate: cancelCancelationMutation,
    isPending: cancelCancelationPending,
  } = useMutation<void, AxiosError, void>({
    mutationFn: () => cancelCancellation(currentSubscription!.id, tenantId!),
    onSuccess: () => {
      showNotification(
        t("Rewards.subscriptionOptions.subscriptionReactivated")
      );
      setTimeout(() => {
        refetchCurrentSubscription();
      }, 1000);
    },
    onError: (error) =>
      showNotification(
        formatErrorMessage(error as AxiosError<HttpValidationProblemDetails>),
        {
          type: "error",
        }
      ),
  });

  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [cancelModalType, setCancelModalType] = useState<
    "cancelSub" | "cancelCancellation" | null
  >(null);
  const closeActionModal = () => {
    setCancelModalOpen(false);
    setTimeout(() => {
      setCancelModalType(null);
    }, 300);
  };

  const [subModalOpen, setSubModalOpen] = useState(false);
  const [subModalData, setSubModalData] = useState<SubModalData | null>(null);
  const openSubModal = (data: SubModalData) => {
    if (!billingData || !paymentData) {
      showNotification(
        commonT("errorCodeMessages.billing-profile-not-configured"),
        {
          type: "error",
        }
      );
    } else {
      setSubModalOpen(true);
      setSubModalData(data);
    }
  };
  const closeSubModal = () => {
    setSubModalOpen(false);
    setTimeout(() => {
      setSubModalData(null);
    }, 300);
  };

  return (
    <Card
      variant="outlined"
      small
      sx={{
        p: 2,
        ...(isCurrentSubscription && {
          borderColor: (theme) => theme.palette.primary.main,
          borderWidth: 1,
          borderStyle: "solid",
        }),
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: { md: "center" },
          justifyContent: { md: "space-between" },
          gap: 1,
          flexDirection: {
            xs: "column",
            md: "row",
          },
        }}
      >
        <Box>
          <Typography fontWeight={700}>{item.name}</Typography>
          <Typography>
            {item.pricePerYear.toLocaleString(lang, {
              style: "currency",
              currency: "PLN",
            })}
            /{t("Rewards.subscriptionOptions.year")}
          </Typography>
          <Typography>
            {item.pricePerMonth.toLocaleString(lang, {
              style: "currency",
              currency: "PLN",
            })}
            /{t("Rewards.subscriptionOptions.month")}
          </Typography>
          <Typography>
            {t("Rewards.subscriptionOptions.operatorsLimit")}:{" "}
            {item.operatorsLimit}
          </Typography>
          <Typography>
            {t("Rewards.subscriptionOptions.rewardsLimit")}: {item.rewardsLimit}
          </Typography>
          <Typography>
            {t("Rewards.subscriptionOptions.locationsLimit")}:{" "}
            {item.locationsLimit}
          </Typography>
          <Typography>
            {t("Rewards.subscriptionOptions.pushLimit")}:{" "}
            {item.pushNotificationToSubscribersLimit}
          </Typography>
          <Typography>
            {t("Rewards.subscriptionOptions.mailLimit")}:{" "}
            {item.emailToSubscribersLimit}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            height: isCurrentSubscription ? "100%" : "auto",
            justifyContent: "space-between",
          }}
        >
          {isCurrentSubscription && <StatusBadge data={currentSubscription!} />}
          {isCurrentSubscription &&
          currentSubscription?.state !== SubscriptionState.Cancelled ? (
            <>
              {(currentSubscription?.state === SubscriptionState.Paid ||
                currentSubscription?.state === SubscriptionState.Trial) &&
                !currentSubscription?.cancelAtPeriodEnd && (
                  <ErrorButton
                    onClick={() => {
                      setCancelModalOpen(true);
                      setCancelModalType("cancelSub");
                    }}
                    disabled={cancelPending}
                  >
                    {t("Rewards.subscriptionOptions.cancelSubscription")}
                  </ErrorButton>
                )}
              {currentSubscription?.cancelAtPeriodEnd && (
                <ErrorButton
                  onClick={() => {
                    setCancelModalOpen(true);
                    setCancelModalType("cancelCancellation");
                  }}
                  disabled={cancelCancelationPending}
                >
                  {t("Rewards.subscriptionOptions.cancelCancelation")}
                </ErrorButton>
              )}
            </>
          ) : (
            <>
              <Button
                color="secondary"
                disabled={
                  buyPending ||
                  (currentSubscription &&
                    currentSubscription?.state !== SubscriptionState.Cancelled)
                }
                onClick={() => {
                  openSubModal({
                    frequency: SubscriptionFrequency.Annually,
                    plan: item,
                  });
                }}
              >
                {t("Rewards.subscriptionOptions.buyYear")}
              </Button>
              <Button
                color="secondary"
                disabled={
                  buyPending ||
                  (currentSubscription &&
                    currentSubscription?.state !== SubscriptionState.Cancelled)
                }
                onClick={() => {
                  openSubModal({
                    frequency: SubscriptionFrequency.Monthly,
                    plan: item,
                  });
                }}
              >
                {t("Rewards.subscriptionOptions.buyMonth")}
              </Button>
            </>
          )}
        </Box>
      </Box>
      <Dialog
        open={cancelModalOpen}
        onClose={closeActionModal}
        fullWidth
        maxWidth="sm"
      >
        {cancelModalType && (
          <>
            <DialogTitle
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {t(
                `Rewards.subscriptionOptions.cancelModal.${cancelModalType}.header`
              )}
              <IconButton onClick={closeActionModal} sx={{ mr: -1 }}>
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {t(
                `Rewards.subscriptionOptions.cancelModal.${cancelModalType}.description`,
                {
                  endDate: new Date(
                    currentSubscription!.currentPeriodEnd
                  ).toLocaleString(lang, {
                    dateStyle: "short",
                  }),
                }
              )}
              <Box
                sx={{ display: "flex", justifyContent: "end", gap: 2, mt: 5 }}
              >
                <Button onClick={closeActionModal} variant="text">
                  {t(
                    `Rewards.subscriptionOptions.cancelModal.${cancelModalType}.no`
                  )}
                </Button>
                <ErrorButton
                  onClick={() => {
                    if (cancelModalType === "cancelSub")
                      cancelSubscriptionMutation();
                    if (cancelModalType === "cancelCancellation")
                      cancelCancelationMutation();
                    closeActionModal();
                  }}
                >
                  {t(
                    `Rewards.subscriptionOptions.cancelModal.${cancelModalType}.yes`
                  )}
                </ErrorButton>
              </Box>
            </DialogContent>
          </>
        )}
      </Dialog>
      <Dialog
        open={subModalOpen}
        onClose={closeSubModal}
        fullWidth
        maxWidth="sm"
      >
        {subModalData && (
          <>
            <DialogTitle
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {t("Rewards.subscriptionOptions.subModal.header")}
              <IconButton onClick={closeSubModal} sx={{ mr: -1 }}>
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <PlanVerification
                prependContent={(eligible) =>
                  eligible ? (
                    <>
                      {t("Rewards.subscriptionOptions.subModal.confirmDesc")}{" "}
                      {subModalData.plan.name} -{" "}
                      {t(
                        `Rewards.subscriptionOptions.subModal.frequency.${subModalData.frequency}`
                      )}
                      ?
                    </>
                  ) : (
                    <>
                      {t(
                        "Rewards.subscriptionOptions.subModal.limitExceededDesc"
                      )}
                    </>
                  )
                }
                planData={subModalData.plan}
                type="new"
                appendContent={(eligible) => (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      gap: 2,
                      mt: 5,
                    }}
                  >
                    {eligible ? (
                      <>
                        <Button onClick={closeSubModal} variant="text">
                          {t("Rewards.subscriptionOptions.subModal.no")}
                        </Button>
                        <Button
                          onClick={() => {
                            subscribe({
                              frequency: subModalData.frequency,
                              planId: subModalData.plan.id,
                              useTrial: isPendingOwner && !currentSubscription,
                            });
                            closeSubModal();
                          }}
                        >
                          {t("Rewards.subscriptionOptions.subModal.yes")}
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button onClick={closeSubModal} variant="text">
                          {t("Rewards.subscriptionOptions.subModal.close")}
                        </Button>
                      </>
                    )}
                  </Box>
                )}
              />
            </DialogContent>
          </>
        )}
      </Dialog>
      <Dialog open={pendingOwnerModalOpen} fullWidth maxWidth="sm">
        <DialogContent>
          {t("Rewards.subscriptionOptions.pendingOwnerModal.content")}
          <Box sx={{ mt: 2, display: "flex", justifyContent: "end" }}>
            <LinkWrapper to={cmsRoutes.home.base}>
              <Button onClick={() => setPendingOwnerModalOpen(false)}>
                {t("Rewards.subscriptionOptions.pendingOwnerModal.button")}
              </Button>
            </LinkWrapper>
          </Box>
        </DialogContent>
      </Dialog>
    </Card>
  );
};
