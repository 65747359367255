import { HttpValidationProblemDetails } from "@repo/types/companyApi.types";
import { QueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        if (
          (error as AxiosError<HttpValidationProblemDetails>)?.response?.data
            ?.type === "subscription-not-found"
        )
          return false;
        if (failureCount < 3) return true;
        return false;
      },
    },
  },
});
