import { PaletteMode } from "@mui/material";
import logoDark from "./assets/logo-dark.svg";
import logoLight from "./assets/logo-light.svg";
import logoShortDark from "./assets/logo-short-dark.svg";
import logoShortLight from "./assets/logo-short-light.svg";
import { getImageUrl } from "@repo/utils";

interface LogoProps {
  type?: "normal" | "mini";
  theme?: PaletteMode;
  height?: React.CSSProperties["height"];
}

const logoMap = {
  normal: {
    light: logoLight,
    dark: logoDark,
  },
  mini: {
    light: logoShortLight,
    dark: logoShortDark,
  },
};

export const Logo = ({
  type = "normal",
  theme = "light",
  height = "100%",
}: LogoProps) => {
  const logoSrc = logoMap[type][theme];

  return (
    <img
      src={getImageUrl(logoSrc)}
      alt="Loymee"
      style={{
        height,
      }}
    />
  );
};
