import { Box, FormGroup, Typography } from "@mui/material";
import { Card, ErrorMessage, LoadingButton, Logo, TextInput } from "@repo/ui";
import {
  cmsRoutes,
  confirmPasswordValidator,
  passwordValidator,
  useNotificationsContext,
  useServerErrorFormatter,
} from "@repo/utils";
import { useForm } from "@tanstack/react-form";
import { valibotValidator } from "@tanstack/valibot-form-adapter";
import { AxiosError } from "axios";
import { useMutation } from "@tanstack/react-query";
import {
  resetPassword,
  ResetPasswordFormRequest,
} from "@repo/api-config/services/company";
import { ResetPasswordRouteParams } from "../../routes/_public/reset-password";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { useContext } from "react";
import { I18nContext, useTranslation } from "@repo/i18n-config";

export const ResetPasswordPage = () => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");

  const { showNotification } = useNotificationsContext();
  const { errorMessage, setError } = useServerErrorFormatter();
  const { code, email, identifier }: ResetPasswordRouteParams = useSearch({
    strict: false,
  });

  if (!code || !email)
    return <ErrorMessage errorMessage={t("ResetPasswordPage.invalidLink")} />;

  const navigate = useNavigate();
  const { mutate: sendForm, isPending } = useMutation<
    void,
    AxiosError,
    ResetPasswordFormRequest
  >({
    mutationFn: resetPassword,
    onSuccess: () => {
      showNotification(t("ResetPasswordPage.passwordHasBeenReset"));
      navigate<string>({ to: cmsRoutes.login.base });
    },
    onError: setError,
  });

  const form = useForm<any, any>({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
    onSubmit: async ({ value }) => {
      sendForm({
        tenantId: identifier || "",
        email,
        newPassword: value.password.trim(),
        resetCode: code,
      });
    },
    validatorAdapter: valibotValidator(),
  });

  return (
    <Card fullscreen>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mb: 3,
        }}
      >
        <Logo height={50} theme="dark" />
      </Box>
      <Typography variant="h5">{t("ResetPasswordPage.header")}</Typography>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
      >
        <FormGroup sx={{ gap: 2, mt: 1 }}>
          <TextInput
            form={form}
            name="password"
            validators={{
              onChange: passwordValidator,
            }}
            type="password"
            label={t("ResetPasswordPage.password")}
            required
          />
          <TextInput
            form={form}
            name="confirmPassword"
            validators={{
              onChangeListenTo: ["password"],
              onChange: confirmPasswordValidator,
            }}
            type="password"
            label={t("ResetPasswordPage.confirmPassword")}
            required
          />
          <LoadingButton
            isLoading={isPending}
            text={t("ResetPasswordPage.send")}
            size="large"
          />
          <ErrorMessage errorMessage={errorMessage} />
        </FormGroup>
      </form>
    </Card>
  );
};
