"use client";

import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  IconButton,
  Link as MuiLink,
} from "@mui/material";
import { Link } from "@tanstack/react-router";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { useContext } from "react";
import { I18nContext, Resources, useTranslation } from "@repo/i18n-config";
import { CompanyStatus } from "./components/CompanyStatus";
import { CompanyVerification } from "./components/CompanyVerification";
import { CompanyVerificationStatus } from "@repo/types/rootApi.types";
import { PlanStatus } from "./components/PlanStatus";
import { DownloadButton } from "./components/DownloadButton";

export interface ShowActions {
  handleEdit?: boolean;
  isDeleteMutationPending?: boolean;
  handleDelete?: (id: string | number) => void;
}

const renderEmptyState = (message: React.ReactNode) => (
  <TableBody>
    <TableRow>
      <TableCell sx={{ textAlign: "center", py: 8 }}>{message}</TableCell>
    </TableRow>
  </TableBody>
);

export const renderTableHeader = (
  columnNames: string[],
  showActions: ShowActions
) => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "ui");

  const { handleEdit, handleDelete } = showActions;
  return (
    <TableHead>
      <TableRow>
        {columnNames.map((columnName, index) => (
          <TableCell key={index}>
            {t(
              `DataTable.headers.${columnName as keyof Resources["ui"]["DataTable"]["headers"]}`
            )}
          </TableCell>
        ))}
        {columnNames.length && (handleEdit || handleDelete) ? (
          <TableCell align="right">{t("DataTable.actions")}</TableCell>
        ) : null}
      </TableRow>
    </TableHead>
  );
};

interface TableItem {
  id: string | number;
  [key: string]: any;
}

export const renderTableBody = (
  items: TableItem[],
  isLoading: boolean,
  columnNames: string[],
  showActions: ShowActions
) => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "ui");

  if (isLoading) {
    return renderEmptyState(<CircularProgress />);
  }

  if (items.length === 0) {
    return renderEmptyState(t("DataTable.noData"));
  }

  const { handleEdit, isDeleteMutationPending, handleDelete } = showActions;

  const formatItem = (item: TableItem, columnName: string) => {
    if (columnName === "verificationStatus") {
      return (
        <CompanyVerification
          id={item.id}
          status={item[columnName] as CompanyVerificationStatus}
          name={item.companyName}
        />
      );
    }
    if (columnName === "isActive") {
      return <CompanyStatus status={item[columnName] as boolean} />;
    }
    if (
      columnName === "pricePerMonth" ||
      columnName === "pricePerYear" ||
      columnName === "price"
    ) {
      return (item[columnName] as number)?.toLocaleString(lang, {
        style: "currency",
        currency: "PLN",
      });
    }
    if (columnName === "createdAt" || columnName === "updatedAt") {
      return new Date(item[columnName] as string).toLocaleString(lang);
    }
    if (columnName === "accessibility")
      return t(
        `DataTable.columns.accessibility.${item[columnName]}` as unknown as `DataTable.columns.accessibility.${keyof Resources["ui"]["DataTable"]["columns"]["accessibility"]}`
      );
    if (columnName === "planPrice")
      return `${
        item[columnName]["price"] !== null
          ? `${(item[columnName]["price"] as number)?.toLocaleString(lang, {
              style: "currency",
              currency: "PLN",
            })}/`
          : ""
      }${t(
        `DataTable.columns.planPrice.subscriptionFrequency.${item[columnName]["subscriptionFrequency"]}` as unknown as `DataTable.columns.planPrice.subscriptionFrequency.${keyof Resources["ui"]["DataTable"]["columns"]["planPrice"]["subscriptionFrequency"]}`
      )}`;
    if (columnName === "subscriptionState")
      return t(
        `DataTable.columns.subscriptionState.${item[columnName]}` as unknown as `DataTable.columns.subscriptionState.${keyof Resources["ui"]["DataTable"]["columns"]["subscriptionState"]}`
      );
    if (columnName === "state")
      return t(
        `DataTable.columns.state.${item[columnName]}` as unknown as `DataTable.columns.state.${keyof Resources["ui"]["DataTable"]["columns"]["state"]}`
      );
    if (columnName === "isAbandoned")
      return <PlanStatus status={item[columnName] as boolean} />;
    if (columnName === "invoicePdfUrl")
      return <DownloadButton href={item[columnName] as string} />;
    return item[columnName];
  };

  return (
    <TableBody>
      {items.map((item) => (
        <TableRow key={item.id}>
          {columnNames.map((columnName, index) => (
            <TableCell
              key={index}
              sx={{ maxWidth: 350, overflowWrap: "break-word" }}
            >
              {formatItem(item, columnName)}
            </TableCell>
          ))}
          {handleEdit || handleDelete ? (
            <TableCell align="right">
              {handleEdit ? (
                <MuiLink component={Link} to={`edit/${item.id}`}>
                  <IconButton>
                    <EditIcon />
                  </IconButton>
                </MuiLink>
              ) : null}
              {handleDelete ? (
                <IconButton
                  onClick={() => handleDelete(item.id)}
                  disabled={isDeleteMutationPending}
                >
                  <DeleteIcon />
                </IconButton>
              ) : null}
            </TableCell>
          ) : null}
        </TableRow>
      ))}
    </TableBody>
  );
};
