import { Box, Typography } from "@mui/material";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import { Card, BillingForm, StripeCardForm } from "@repo/ui";
import { useContext } from "react";

const PaymentsInfo = () => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");

  return (
    <Card
      title={t("Rewards.paymentsInfo.cardTitle")}
      sx={{
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        "& > h6": {
          width: { xs: "100%", sm: "40%" },
        },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1, gap: 2 }}>
        <Typography variant="h6">
          {t("Rewards.paymentsInfo.billingData")}
        </Typography>
        <BillingForm />
        <div id="card">
          <Typography variant="h6">
            {t("Rewards.paymentsInfo.yourCard")}
          </Typography>
        </div>
        <StripeCardForm />
      </Box>
    </Card>
  );
};

export default PaymentsInfo;
