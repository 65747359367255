import {
  AddLocationCommand,
  AddLocationResult,
  LocationDto,
} from "@repo/types/companyApi.types";
import { httpClient } from "../../http-client";
import { companyApiPaths } from "./paths";

export const getLocationsList = async (tenantId: string) => {
  const response = await httpClient.get<LocationDto[]>(
    `${companyApiPaths.locations.base(tenantId)}`
  );

  return response.data;
};

export const addLocation = async (
  tenantId: string,
  { externalId }: AddLocationCommand
) => {
  const response = await httpClient.post<AddLocationResult>(
    companyApiPaths.locations.base(tenantId),
    {
      externalId,
    }
  );

  return response.data;
};

export const deleteLocation = async (tenantId: string, id: string) => {
  const response = await httpClient.delete(
    companyApiPaths.locations.delete(tenantId, id)
  );

  return response.data;
};
