import { Box, Button, Typography } from "@mui/material";
import { confirmCompany, deleteCompany } from "@repo/api-config/services/root";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import { CompanyVerificationStatus } from "@repo/types/rootApi.types";
import { useNotificationsContext } from "@repo/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useContext, useState } from "react";
import { ConfirmModal } from "../../ConfirmModal";

interface CompanyStatusProps {
  id: string | number;
  status: CompanyVerificationStatus;
  name: string;
}

export const CompanyVerification = ({
  id,
  status,
  name,
}: CompanyStatusProps) => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "ui");

  const { showNotification } = useNotificationsContext();
  const queryClient = useQueryClient();

  const [modalOpen, setModalOpen] = useState(false);
  const [action, setAction] = useState<"confirm" | "delete" | null>(null);

  const {
    mutate: confirmCompanyMutation,
    isPending: isConfirmMutationPending,
  } = useMutation<any, AxiosError, any>({
    mutationFn: () => confirmCompany(id.toString()),
    onSuccess: () => {
      showNotification(
        t("DataTable.columns.verificationStatus.companyConfirmed")
      );
      queryClient.invalidateQueries({
        queryKey: ["Companies"],
      });
    },
    onError: () => {
      showNotification(
        t("DataTable.columns.verificationStatus.companyConfirmFail"),
        {
          type: "error",
        }
      );
    },
  });

  const { mutate: deleteCompanyMutation, isPending: isDeleteMutationPending } =
    useMutation<any, AxiosError, any>({
      mutationFn: () => deleteCompany(id.toString()),
      onSuccess: () => {
        showNotification(
          t("DataTable.columns.verificationStatus.companyDeleted")
        );
        queryClient.invalidateQueries({
          queryKey: ["Companies"],
        });
      },
      onError: () => {
        showNotification(
          t("DataTable.columns.verificationStatus.companyDeleteFail"),
          {
            type: "error",
          }
        );
      },
    });

  if (status === CompanyVerificationStatus.Confirmed)
    return (
      <Typography variant="body2">
        {t(`DataTable.columns.verificationStatus.Confirmed`)}
      </Typography>
    );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: {
          xs: "column",
          lg: "row",
        },
      }}
    >
      <Button
        size="small"
        variant="text"
        onClick={() => {
          setAction("confirm");
          setModalOpen(true);
        }}
        disabled={isConfirmMutationPending || isDeleteMutationPending}
      >
        {t("DataTable.columns.verificationStatus.confirm")}
      </Button>
      <Button
        size="small"
        variant="text"
        color="error"
        onClick={() => {
          setAction("delete");
          setModalOpen(true);
        }}
        disabled={isConfirmMutationPending || isDeleteMutationPending}
      >
        {t("DataTable.columns.verificationStatus.reject")}
      </Button>
      <ConfirmModal
        open={modalOpen}
        handleNo={() => {
          setModalOpen(false);
          setTimeout(() => {
            setAction(null);
          }, 300);
        }}
        handleYes={() => {
          setModalOpen(false);
          action === "confirm"
            ? confirmCompanyMutation(id)
            : deleteCompanyMutation(id);
          setTimeout(() => {
            setAction(null);
          }, 300);
        }}
        text={
          action
            ? t(`DataTable.columns.verificationStatus.confirmModal.${action}`, {
                name,
              })
            : ""
        }
      />
    </Box>
  );
};
