import { createFileRoute, redirect } from "@tanstack/react-router";
import { EditCompany } from "../../../../pages/Companies/EditCompany";
import { getCompanyById } from "@repo/api-config/services/root";

export const Route = createFileRoute("/_private/companies/edit/$companyId")({
  component: () => <EditCompany />,
  beforeLoad: ({ context }) => {
    const { getUserRole } = context.auth;
    const role = getUserRole();

    if (role !== "Admin") {
      throw redirect<any>({
        to: "/",
      });
    }
  },
  loader: async ({ params }) => {
    const company = await getCompanyById(params.companyId);
    return company;
  },
});
