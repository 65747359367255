"use client";

import {
  alpha,
  Box,
  Card,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  BarChart,
  Bar,
} from "recharts";
import { useContext, useEffect, useMemo } from "react";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import { useQuery } from "@tanstack/react-query";
import { getStatisticsChart as getRootStatisticsChart } from "@repo/api-config/services/root";
import { useServerErrorFormatter } from "@repo/utils";
import { AxiosError } from "axios";
import { ErrorMessage } from "../ErrorMessage";

export const DashboardPlansUsageChart = () => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { errorMessage, setError } = useServerErrorFormatter();
  const { data, error, isLoading } = useQuery({
    queryFn: () => {
      setError(null);

      return getRootStatisticsChart("Year");
    },
    queryKey: ["DashboardStatisticsChart", "Year"],
  });
  useEffect(() => {
    if (error) setError(error as AxiosError);
  }, [error]);

  const formattedData = useMemo(() => {
    if (data) {
      const values = Object.values(data.currentPlansUsage);

      return values.map((value) => ({
        name: value.name,
        value: 3 as number,
      }));
    }
  }, [data]);

  const yAxisDomain = useMemo(() => {
    if (formattedData) {
      const maxY = Math.max(...formattedData.map((d) => d.value!));
      if (maxY > 5) return undefined;
      return [0, 5];
    }
    return undefined;
  }, [formattedData]);

  return (
    <Card
      sx={{
        py: 2.5,
        borderRadius: 4,
      }}
      elevation={4}
    >
      <Typography
        variant="h6"
        sx={{
          ml: 4,
          mb: 4,
        }}
      >
        {t("dashboard.chart.plansUsage")}
      </Typography>
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
      {formattedData && formattedData?.length > 0 ? (
        <ResponsiveContainer height={450} width="100%">
          <BarChart
            data={formattedData}
            margin={
              isMobile
                ? { left: -16, right: 20, bottom: 20 }
                : { bottom: 20, right: 20 }
            }
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="0%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0.2}
                />
                <stop
                  offset="100%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="name"
              tickLine={false}
              axisLine={false}
              fontSize={14}
              stroke={theme.palette.grey[500]}
              tickMargin={8}
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              fontSize={14}
              stroke={theme.palette.grey[500]}
              tickMargin={8}
              domain={yAxisDomain}
              tickCount={6}
            />
            <CartesianGrid vertical={false} stroke={theme.palette.grey[500]} />
            <Bar
              dataKey="value"
              stroke={theme.palette.primary.main}
              fillOpacity={1}
              strokeWidth={2}
              fill="url(#colorUv)"
            />
            <Tooltip
              cursor={{ fill: alpha(theme.palette.primary.contrast!, 0.3) }}
              separator=": "
              contentStyle={{
                borderRadius: "8px",
                color: theme.palette.primary.contrast,
              }}
              itemStyle={{
                color: theme.palette.primary.contrast,
              }}
              formatter={(value) => [value, t("dashboard.chart.planUsage")]}
            />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <>
          {!isLoading && (
            <Typography textAlign="center">
              {t("dashboard.chart.noData")}
            </Typography>
          )}
        </>
      )}
    </Card>
  );
};
