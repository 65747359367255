import { useMemo } from "react";
import { isAuthenticated, useCmsRole, useMe } from "../api-config";

export interface Permissions {
  canCreate: boolean;
  canRead: boolean;
  canUpdate: boolean;
  canDelete: boolean;
}

export interface ResourcePermissions {
  customers: Permissions;
  subscriptions: Permissions;
  companies: Permissions;
  employees: Permissions;
  rewards: Permissions;
  locations: Permissions;
  settings: Permissions;
}

export enum FlatRoles {
  ADMIN = "ADMIN",
  CUSTOMER = "CUSTOMER",
  OWNER = "OWNER",
  OPERATOR = "OPERATOR",
}

const NO_ACCESS: Permissions = {
  canCreate: false,
  canRead: false,
  canUpdate: false,
  canDelete: false,
};

const FULL_ACCESS: Permissions = {
  canCreate: true,
  canRead: true,
  canUpdate: true,
  canDelete: true,
};

const READ_ONLY: Permissions = {
  canCreate: false,
  canRead: true,
  canUpdate: false,
  canDelete: false,
};

const rolePermissions: Record<FlatRoles, ResourcePermissions> = {
  [FlatRoles.ADMIN]: {
    customers: FULL_ACCESS,
    subscriptions: FULL_ACCESS,
    companies: FULL_ACCESS,
    employees: READ_ONLY,
    rewards: NO_ACCESS,
    locations: NO_ACCESS,
    settings: NO_ACCESS,
  },
  [FlatRoles.CUSTOMER]: {
    customers: NO_ACCESS,
    subscriptions: NO_ACCESS,
    companies: NO_ACCESS,
    employees: NO_ACCESS,
    rewards: NO_ACCESS,
    locations: READ_ONLY,
    settings: FULL_ACCESS,
  },
  [FlatRoles.OWNER]: {
    customers: READ_ONLY,
    subscriptions: NO_ACCESS,
    companies: NO_ACCESS,
    employees: FULL_ACCESS,
    rewards: FULL_ACCESS,
    locations: FULL_ACCESS,
    settings: FULL_ACCESS,
  },
  [FlatRoles.OPERATOR]: {
    customers: NO_ACCESS,
    subscriptions: NO_ACCESS,
    companies: READ_ONLY,
    employees: NO_ACCESS,
    rewards: NO_ACCESS,
    locations: NO_ACCESS,
    settings: NO_ACCESS,
  },
};

export const defaultPermissions = {
  canCreate: false,
  canRead: false,
  canUpdate: false,
  canDelete: false,
};

const permissionsMap = {
  Admin: rolePermissions["ADMIN"],
  Customer: rolePermissions["CUSTOMER"],
  Employee: {
    Operator: rolePermissions["OPERATOR"],
    Owner: rolePermissions["OWNER"],
    PendingOwner: rolePermissions["OWNER"],
  },
};

export const usePermission = (resource: keyof ResourcePermissions) => {
  try {
    const { userType, userRole } = useMe(useCmsRole(), isAuthenticated());

    const permissions = useMemo(() => {
      switch (userType) {
        case "Employee":
          if (userRole && userType) {
            return permissionsMap[userType][userRole][resource];
          }
          return undefined;
        case "Admin":
        case "Customer":
          return permissionsMap[userType][resource];
        default:
          return undefined;
      }
    }, [userType, userRole, resource]);

    return permissions;
  } catch (error) {
    console.log(error);
    return defaultPermissions;
  }
};

export const useAllPermissions = () => {
  return {
    rewards: usePermission("rewards"),
    employees: usePermission("employees"),
    customers: usePermission("customers"),
    locations: usePermission("locations"),
    companies: usePermission("companies"),
    subscriptions: usePermission("subscriptions"),
    settings: usePermission("settings"),
  };
};
