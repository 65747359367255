import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_public/sentry-test/")({
  component: () => (
    <>
      <button
        onClick={() => {
          throw new Error();
        }}
      >
        Break the world
      </button>
    </>
  ),
});
