import { Box, Typography } from "@mui/material";
import { getTenantId } from "@repo/api-config";
import {
  addLocation,
  getLocationsList,
} from "@repo/api-config/services/company";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import {
  AddLocationCommand,
  AddLocationResult,
} from "@repo/types/companyApi.types";
import { Button, Card, MapPickerComponent, MapPlacesProvider } from "@repo/ui";
import {
  cmsRoutes,
  getCookie,
  useNotificationsContext,
  useServerErrorFormatter,
} from "@repo/utils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { APIProvider as GoogleMapsAPIProvider } from "@vis.gl/react-google-maps";
import { AxiosError } from "axios";
import { useContext, useState } from "react";

export const NewLocation = () => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");

  const [place, setPlace] = useState<google.maps.places.PlaceResult | null>();
  const { showNotification } = useNotificationsContext();
  const { setError } = useServerErrorFormatter();
  const navigate = useNavigate();
  const tenantId = getTenantId();
  const queryClient = useQueryClient();
  const isPendingOwner = getCookie("loymeeUserRole") === "PendingOwner";

  const { data: locationsList } = useQuery({
    queryFn: () => getLocationsList(tenantId!),
    queryKey: ["locations"],
  });

  const { mutate: addLocationMutation } = useMutation<
    AddLocationResult,
    AxiosError,
    AddLocationCommand
  >({
    mutationFn: (values) => addLocation(tenantId!, values),
    onError: setError,
    onSuccess: () => {
      const firstLocation = locationsList?.length === 0;
      queryClient.removeQueries({
        queryKey: ["locations"],
      });
      queryClient.removeQueries({
        queryKey: ["PlacesGoogleData"],
      });
      showNotification(t("Locations.locationAdded"));
      navigate({
        to:
          isPendingOwner && firstLocation
            ? `${cmsRoutes.locations.base}?pending=true`
            : cmsRoutes.locations.base,
      });
    },
  });

  const handleAddLocation = () => {
    if (!place?.geometry?.location) return;

    addLocationMutation({
      externalId: place.place_id!,
    });
  };

  return (
    <GoogleMapsAPIProvider
      apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}
      libraries={["places", "marker"]}
    >
      <MapPlacesProvider>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {t("Locations.location")}
        </Typography>
        <Card>
          <MapPickerComponent
            showSearchBox
            onChangePlace={(place) => setPlace(place)}
          />
        </Card>
        <Box sx={{ mt: 2, textAlign: "right" }}>
          <Button onClick={handleAddLocation} disabled={!place}>
            {t("Locations.save")}
          </Button>
        </Box>
      </MapPlacesProvider>
    </GoogleMapsAPIProvider>
  );
};
