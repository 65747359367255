import { LocationDto } from "@repo/types/companyApi.types";
import { ExtendedLocationDto } from "@repo/types/customerApi.types";

export type ExtendedLocationDtoWithGoogleData = ExtendedLocationDto & {
  latitude: number;
  longitude: number;
  formattedAddress: string;
  isOpen: boolean;
  openingHours?: string[];
  placePhotos?: google.maps.places.PlacePhoto[];
  internalId?: number;
};

const formatAddressSpan = (span: string) => {
  // Create a DOM parser
  const parser = new DOMParser();
  const doc = parser.parseFromString(span, "text/html");

  // Initialize an empty object
  const result: Record<string, string> = {};

  // Select all span elements and populate the result object
  doc.querySelectorAll("span").forEach((span) => {
    const key = span.className;
    const value = span.textContent?.trim();
    if (key && value) result[key] = value;
  });

  return `${result["street-address"] || ""}, ${result["locality"] || ""}`;
};

export const fetchGoogleDetails = async (
  svc: google.maps.places.PlacesService,
  locations: ExtendedLocationDto[] | LocationDto[],
  lang: string
): Promise<ExtendedLocationDtoWithGoogleData[]> => {
  if (!locations || locations.length === 0) return [];

  const detailedLocations = await Promise.all(
    locations.map((location) => {
      return new Promise<ExtendedLocationDtoWithGoogleData>(
        (resolve, reject) => {
          svc.getDetails(
            {
              placeId: location.externalId,
              language: lang,
            },
            (data, status) => {
              if (status === google.maps.places.PlacesServiceStatus.OK) {
                resolve({
                  ...location,
                  latitude: data?.geometry?.location?.lat(),
                  longitude: data?.geometry?.location?.lng(),
                  isOpen: data?.opening_hours?.isOpen() ?? false,
                  openingHours: data?.opening_hours?.weekday_text,
                  formattedAddress: formatAddressSpan(data?.adr_address || ""),
                  placePhotos: data?.photos,
                  internalId:
                    location && "id" in location ? location.id : undefined,
                } as ExtendedLocationDtoWithGoogleData);
              } else {
                reject(
                  new Error(
                    "Google Details Fetch Failed for placeId: " +
                      location.externalId
                  )
                );
              }
            }
          );
        }
      );
    })
  );

  return detailedLocations;
};
