"use client";

import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  FormGroup,
} from "@mui/material";
import { getTenantId } from "@repo/api-config";
import {
  createBillingData,
  getBillingData,
  updateBillingData,
} from "@repo/api-config/services/company";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import {
  emailValidator,
  getCookie,
  nipValidator,
  requiredStringValidator,
  useNotificationsContext,
  useServerErrorFormatter,
  zipCodeValidator,
} from "@repo/utils";
import { useForm } from "@tanstack/react-form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { valibotValidator } from "@tanstack/valibot-form-adapter";
import { AxiosError } from "axios";
import { useContext, useEffect, useState } from "react";
import { ErrorMessage } from "../ErrorMessage";
import { TextInput } from "../form-fields/TextInput";
import { Button } from "../Button";
import {
  BillingAccountType,
  CreateBillingProfileCommand,
  UpdateBillingProfileCommand,
} from "@repo/types/companyApi.types";
import { PostcodeTextInput } from "../form-fields/PostcodeTextInput";

interface BillingFormProps {
  onSuccess?: () => void;
}

export const BillingForm = ({ onSuccess }: BillingFormProps) => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");

  const tenantId = getTenantId();
  const { errorMessage: dataGetError, setError: setDataGetError } =
    useServerErrorFormatter();
  const { data, isLoading, error } = useQuery({
    queryFn: () => getBillingData(tenantId!),
    queryKey: ["BillingData"],
    enabled: !!tenantId,
  });
  useEffect(() => {
    if (error) setDataGetError(error as AxiosError);
  }, [error]);

  const isPendingOwner = getCookie("loymeeUserRole") === "PendingOwner";
  const [pendingOwnerModalOpen, setPendingOwnerModalOpen] = useState(false);

  const { errorMessage: dataSaveError, setError: setDataSaveError } =
    useServerErrorFormatter();
  const { showNotification } = useNotificationsContext();
  const queryClient = useQueryClient();
  const { mutate: saveData } = useMutation<
    void,
    AxiosError,
    CreateBillingProfileCommand | UpdateBillingProfileCommand
  >({
    mutationFn: (values) =>
      data
        ? updateBillingData(tenantId!, values)
        : createBillingData(tenantId!, values),
    onError: setDataSaveError,
    onSuccess: () => {
      showNotification(t("billingForm.saved"));
      queryClient.invalidateQueries({
        queryKey: ["BillingData"],
      });
      onSuccess && onSuccess();
      isPendingOwner && setPendingOwnerModalOpen(true);
    },
  });

  const form = useForm<
    CreateBillingProfileCommand | UpdateBillingProfileCommand,
    any
  >({
    defaultValues: {
      company: "",
      firstName: "",
      lastName: "",
      billingEmail: "",
      vat: "",
      city: "",
      address: "",
      zipCode: "",
      country: "PL",
      accountType: BillingAccountType.Business,
    },
    onSubmit: ({ value }) => {
      saveData({ ...value, vat: `PL${value.vat?.replace("-", "")}` });
    },
    validatorAdapter: valibotValidator(),
  });

  useEffect(() => {
    if (data) {
      form.setFieldValue("company", data.company ?? "");
      form.setFieldValue("firstName", data.firstName ?? "");
      form.setFieldValue("lastName", data.lastName ?? "");
      form.setFieldValue("billingEmail", data.billingEmail ?? "");
      form.setFieldValue("vat", data.vat.replace("PL", "") ?? "");
      form.setFieldValue("city", data.city ?? "");
      form.setFieldValue("address", data.address ?? "");
      form.setFieldValue("zipCode", data.zipCode ?? "");
      form.setFieldValue("accountType", BillingAccountType.Business);
      form.setFieldValue("country", "PL");
    }
  }, [data]);

  if (isLoading)
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  if (dataGetError && (error as AxiosError)?.status !== 404 && !data)
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <ErrorMessage errorMessage={dataGetError} />
      </Box>
    );
  return (
    <Box
      component="form"
      sx={{ display: "flex", flex: 1, pt: 2 }}
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <FormGroup sx={{ gap: 2 }}>
          <TextInput
            form={form}
            name="company"
            validators={{
              onChange: requiredStringValidator,
            }}
            label={t("billingForm.company")}
            required
          />
          <TextInput
            form={form}
            name="firstName"
            validators={{
              onChange: requiredStringValidator,
            }}
            label={t("billingForm.firstName")}
            required
          />
          <TextInput
            form={form}
            name="lastName"
            validators={{
              onChange: requiredStringValidator,
            }}
            label={t("billingForm.lastName")}
            required
          />
          <TextInput
            form={form}
            name="billingEmail"
            validators={{
              onChange: emailValidator,
            }}
            label={t("billingForm.billingEmail")}
            required
          />
          <TextInput
            form={form}
            name="vat"
            validators={{
              onChange: nipValidator,
            }}
            label={t("billingForm.vat")}
            required
          />
          <FormGroup sx={{ gap: 2, flexDirection: "row" }}>
            <Box
              sx={{
                width: "calc(50% - 8px)",
              }}
            >
              <TextInput
                form={form}
                name="city"
                validators={{
                  onChange: requiredStringValidator,
                }}
                label={t("billingForm.city")}
                required
              />
            </Box>
            <Box
              sx={{
                width: "calc(50% - 8px)",
              }}
            >
              <PostcodeTextInput
                form={form}
                name="zipCode"
                validators={{
                  onChange: zipCodeValidator,
                }}
                label={t("billingForm.zipCode")}
                required
              />
            </Box>
          </FormGroup>
          <TextInput
            form={form}
            name="address"
            validators={{
              onChange: requiredStringValidator,
            }}
            label={t("billingForm.address")}
            required
          />
        </FormGroup>
        {dataSaveError ? <ErrorMessage errorMessage={dataSaveError} /> : null}
        <form.Subscribe
          selector={(state) => state.isValid && !state.isPristine}
          children={(isValid) => (
            <Button
              type="submit"
              sx={{ mt: 4, alignSelf: "flex-end" }}
              variant="contained"
              disabled={!isValid}
            >
              {t("billingForm.save")}
            </Button>
          )}
        />
      </Box>
      <Dialog open={pendingOwnerModalOpen} fullWidth maxWidth="sm">
        <DialogContent>
          {t("billingForm.pendingOwnerModal.content")}
          <a
            href="#card"
            style={{ marginTop: 16, display: "flex", justifyContent: "end" }}
          >
            <Button onClick={() => setPendingOwnerModalOpen(false)}>
              {t("billingForm.pendingOwnerModal.button")}
            </Button>
          </a>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
