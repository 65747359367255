import { createFileRoute, redirect } from "@tanstack/react-router";
import { EditReward } from "../../../../pages/Rewards/NewReward";
import { getRewardsList } from "@repo/api-config/services/company";
import { cmsRoutes } from "@repo/utils";
import { getTenantId } from "@repo/api-config";

export const Route = createFileRoute("/_private/rewards/edit/$rewardId")({
  component: () => <EditReward />,
  beforeLoad: ({ context }) => {
    const { canUpdate } = context.permissions.rewards;

    if (!canUpdate) {
      throw redirect<any>({
        to: cmsRoutes.rewards.base,
      });
    }
  },
  loader: async ({ params }) => {
    const tenantId = getTenantId();
    const rewards = await getRewardsList(tenantId!);
    return rewards.find((reward) => reward.id === parseInt(params.rewardId));
  },
});
