/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AccessTokenResponse {
  tokenType: string;
  accessToken: string;
  /** @format int64 */
  expiresIn: number;
  refreshToken: string;
}

export interface AddPlanCommand {
  /** @maxLength 200 */
  name: string;
  /**
   * @format double
   * @min 0
   */
  pricePerMonth: number;
  /** @format double */
  pricePerYear: number;
  /**
   * @format int32
   * @min 1
   */
  operatorsLimit: number;
  /**
   * @format int32
   * @min 1
   */
  rewardsLimit: number;
  /** @format int32 */
  locationLimit: number;
  /** @format int32 */
  emailToSubscriberLimit: number;
  /** @format int32 */
  pushNotificationToSubscribersLimit: number;
  accessibility: PlanAccessibility;
}

export interface AddPlanResult {
  /** @format int32 */
  planId: number;
}

export enum ChartDateRange {
  Year = "Year",
  Month = "Month",
  Week = "Week",
}

export interface CompanyBaseStatsDto {
  /** @format int32 */
  customerCount: number;
  /** @format int32 */
  rewardQuantity: number;
  /** @format int32 */
  claimedRewardCount: number;
}

export interface CompanyDto {
  identifier: string;
  name: string;
  verificationStatus: CompanyVerificationStatus;
  phoneNumber: string;
  pointsType: string;
  /** @format int32 */
  multiplier: number;
}

export interface CompanySubscriptionDto {
  /** @format int32 */
  planId?: number | null;
  planName?: string | null;
  /** @format double */
  price?: number | null;
  subscriptionFrequency?: SubscriptionFrequency | null;
  subscriptionState: SubscriptionState;
}

export interface CompanySummaryDto {
  identifier: string;
  name: string;
  verificationStatus: CompanyVerificationStatus;
  isActive: boolean;
  statistics: CompanyBaseStatsDto;
  subscription: CompanySubscriptionDto;
}

export interface CompanySummaryDtoPaginatedList {
  items: CompanySummaryDto[];
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  totalCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export enum CompanyVerificationStatus {
  Pending = "Pending",
  Confirmed = "Confirmed",
  Rejected = "Rejected",
}

export type HttpValidationProblemDetails = ProblemDetails & {
  errors?: Record<string, string[]>;
  [key: string]: any;
};

export interface LoginRequest {
  email: string;
  password: string;
}

export interface NoContent {
  /** @format int32 */
  statusCode: number;
}

export enum PlanAccessibility {
  Standalone = "Standalone",
  Custom = "Custom",
}

export interface PlanDto {
  /** @format int32 */
  id: number;
  name: string;
  /** @format double */
  pricePerMonth: number;
  /** @format double */
  pricePerYear: number;
  /** @format int32 */
  operatorsLimit: number;
  /** @format int32 */
  locationsLimit: number;
  /** @format int32 */
  rewardsLimit: number;
  /** @format int32 */
  emailToSubscribersLimit: number;
  /** @format int32 */
  pushNotificationToSubscribersLimit: number;
  /** @format date-time */
  created: string;
  accessibility: PlanAccessibility;
  isAbandoned: boolean;
}

export interface PlanDtoPaginatedList {
  items: PlanDto[];
  /** @format int32 */
  pageNumber: number;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  totalPages: number;
  /** @format int32 */
  totalCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export interface RefreshRequest {
  refreshToken: string;
}

export interface RootChartStatisticsDto {
  purchaseScanCountByDay: Record<string, number>;
  claimedRewardsCountByDay: Record<string, number>;
  newCustomersCountByDay: Record<string, number>;
  newCompaniesCountByDay: Record<string, number>;
  currentPlansUsage: StatisticsPlanUsageDto[];
}

export interface RootNumericStatisticsDto {
  /** @format int32 */
  existingCustomerCount: number;
  /** @format int32 */
  existingCompaniesCount: number;
  /** @format int32 */
  activeCompaniesCount: number;
  /** @format int32 */
  purchaseScanCount: number;
  /** @format int32 */
  claimedRewardCount: number;
}

export interface SetVerificationStatusCommand {
  identifier: string;
  isConfirmed: boolean;
}

export interface StatisticsPlanUsageDto {
  /** @format int32 */
  planId: number;
  name: string;
  /** @format int32 */
  companyCount: number;
}

export enum SubscriptionFrequency {
  Monthly = "Monthly",
  Annually = "Annually",
}

export enum SubscriptionState {
  Unknown = "Unknown",
  Provisioned = "Provisioned",
  Trial = "Trial",
  Paid = "Paid",
  AwaitingPayment = "AwaitingPayment",
  Cancelled = "Cancelled",
  Failed = "Failed",
  Expired = "Expired",
}

export interface UpdateCompanyCommand {
  companyName?: string | null;
  phoneNumber?: string | null;
  pointsType?: string | null;
  /** @format int32 */
  multiplier?: number | null;
}

export interface UserInfoResponse {
  email: string;
  isEmailConfirmed: boolean;
  userType: string;
  userRole?: string | null;
  tenantIdentifier?: string | null;
}
