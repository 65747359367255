import { FlagVm } from "@repo/types/companyApi.types";
import { httpClient } from "../../http-client";
import { companyApiPaths } from "./paths";

export const getFlags = async (tenantId: string) => {
  const response = await httpClient.get<FlagVm>(
    companyApiPaths.flags.base(tenantId)
  );

  return response.data;
};
