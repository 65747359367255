import { Card, ErrorMessage, LoadingButton, TextInput } from "@repo/ui";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import { useLoaderData } from "@tanstack/react-router";
import { useContext } from "react";
import { useForm } from "@tanstack/react-form";
import {
  editCompany,
  EditCompanyRequest,
} from "@repo/api-config/services/root";
import { valibotValidator } from "@tanstack/valibot-form-adapter";
import { Box } from "@mui/material";
import {
  requiredNumberValidator,
  requiredStringValidator,
  useNotificationsContext,
  useServerErrorFormatter,
} from "@repo/utils";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

export const EditCompany = () => {
  const loaderData = useLoaderData({
    from: "/_private/companies/edit/$companyId",
  });
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");

  const { showNotification } = useNotificationsContext();
  const { errorMessage, setError } = useServerErrorFormatter();

  const { mutate: updateCompany } = useMutation<
    void,
    AxiosError,
    EditCompanyRequest
  >({
    mutationFn: (values) => editCompany(loaderData.identifier, values),
    onError: setError,
    onSuccess: () => {
      showNotification(t("Companies.companyEdited"));
    },
  });

  const form = useForm<EditCompanyRequest, any>({
    defaultValues: {
      companyName: loaderData.name,
      phoneNumber: loaderData.phoneNumber,
      multiplier: loaderData.multiplier,
    },
    onSubmit: ({ value }) => {
      updateCompany(value);
    },
    validatorAdapter: valibotValidator(),
  });

  return (
    <Card
      title={t("Companies.editCardTitle")}
      sx={{
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        "& h6": {
          width: { xs: "100%", sm: "40%" },
        },
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flex: 1 }}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            gap: 2,
          }}
        >
          <TextInput
            form={form}
            name="companyName"
            label={t("Companies.editForm.companyName")}
            required
            validators={{ onChange: requiredStringValidator }}
          />
          <TextInput
            form={form}
            name="phoneNumber"
            label={t("Companies.editForm.phoneNumber")}
            required
            validators={{ onChange: requiredStringValidator }}
          />
          <TextInput
            form={form}
            name="multiplier"
            label={t("Companies.editForm.multiplier")}
            required
            type="number"
            validators={{ onChange: requiredNumberValidator }}
          />
          {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
          <LoadingButton
            sx={{
              mt: 4,
              alignSelf: "flex-end",
              backgroundColor: (theme) => theme.palette.secondary.main,
            }}
            isLoading={false}
            text={t("Rewards.points.save")}
            fullWidth={false}
          />
        </Box>
      </Box>
    </Card>
  );
};
