"use client";

import { useState, useEffect, useContext } from "react";
import { AxiosError } from "axios";
import { HttpValidationProblemDetails } from "@repo/types/companyApi.types";
import { I18nContext, Resources, useTranslation } from "../i18n-config";

const errorCodeKeys = [
  "email-already-taken",
  "email-not-confirmed",
  "errors-occurred",
  "insufficient-points-quantity",
  "invalid-code",
  "invalid-external-auth-info",
  "location-already-exist",
  "name-already-taken",
  "tenant-already-exist",
  "subscription-expired",
  "subscription-not-found",
  "billing-profile-not-configured",
  "plan-not-allowed-the-action",
  "forbidden-action",
  "company-not-found",
  "user-not-found",
  "auth-method-not-available",
  "https://tools.ietf.org/html/rfc7231#section-6.5.1",
  "https://tools.ietf.org/html/rfc7235#section-3.1",
  "https://tools.ietf.org/html/rfc7231#section-6.5.3",
  "https://tools.ietf.org/html/rfc7231#section-6.5.4",
  "subscription-downgrade-not-possible",
  "already-subscribed",
  "subscription-cancellation-non-cancellable",
  "subscription-canceled",
] as const;
type ErrorCodeKey = (typeof errorCodeKeys)[number];

interface UseServerErrorFormatterResult {
  errorMessage: string;
  setError: (error: AxiosError | null) => void;
  formatErrorMessage: (
    error: AxiosError<HttpValidationProblemDetails>
  ) => string;
}

export const useServerErrorFormatter = (): UseServerErrorFormatterResult => {
  const [error, setError] = useState<AxiosError<any> | null>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "common");

  useEffect(() => {
    if (error) {
      setErrorMessage(formatErrorMessage(error));
    } else setErrorMessage("");
  }, [error]);

  const handleSetError = (error: AxiosError | null) => {
    setError(error);
  };

  const formatErrorMessage = (
    error: AxiosError<HttpValidationProblemDetails>
  ) => {
    if (error?.response?.data.errors) {
      return Object.keys(error.response.data.errors)
        .map((error) =>
          t(
            `errorCodeMessages.validation.${error as unknown as keyof Resources["common"]["errorCodeMessages"]["validation"]}`,
            {
              nsSeparator: "",
            }
          )
        )
        .toString();
    } else if (
      error?.response?.data.type &&
      errorCodeKeys.find((v) => v === error?.response?.data.type)
    ) {
      return t(
        `errorCodeMessages.${error?.response?.data.type as ErrorCodeKey}`,
        {
          nsSeparator: "",
        }
      );
    } else if (error?.response?.data.status) {
      switch (error.response.data.status) {
        case 401:
          return t(
            "errorCodeMessages.https://tools.ietf.org/html/rfc7235#section-3.1",
            {
              nsSeparator: "",
            }
          );
        case 402:
          return t("errorCodeMessages.error402");
        case 500:
          return t("errorCodeMessages.noServerConnection");
        default:
          return `Error: ${error.response.statusText}`;
      }
    } else if (error?.response?.data) {
      return error.response.data.title as string;
    } else if (error?.code === "ERR_NETWORK") {
      if (error.config?.url?.includes("/auth/login"))
        return t(
          "errorCodeMessages.https://tools.ietf.org/html/rfc7235#section-3.1",
          {
            nsSeparator: "",
          }
        );
      return t(`errorCodeMessages.noServerConnection`);
    } else {
      return `Error: ${error.message}`;
    }
  };

  return { errorMessage, setError: handleSetError, formatErrorMessage };
};
