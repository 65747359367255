import { createFileRoute, redirect } from "@tanstack/react-router";
import { CustomersPage } from "../../../pages/Customers/CustomersPage";

export const Route = createFileRoute("/_private/customers/")({
  component: () => <CustomersPage />,
  beforeLoad: ({ context }) => {
    const { canRead } = context.permissions.customers;

    if (!canRead) {
      throw redirect<any>({
        to: "/",
      });
    }
  },
});
