import { Box } from "@mui/material";
import {
  deleteCompany,
  getCompaniesList,
} from "@repo/api-config/services/root";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import { ConfirmModal, DataTable, RowsPerPageOption } from "@repo/ui";
import { useNotificationsContext } from "@repo/utils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useContext, useMemo, useState } from "react";
import { DeletedItem } from "../../@types";

export const CompaniesPage = () => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState<RowsPerPageOption>(10);

  const { data, isLoading, refetch } = useQuery({
    queryFn: () => getCompaniesList({ pageParam: { pageNumber, pageSize } }),
    queryKey: ["Companies", pageNumber, pageSize],
  });

  const companiesList = useMemo(() => {
    if (data)
      return {
        ...data,
        items: data.items.map((obj) => {
          const { identifier, name, subscription, statistics, ...rest } = obj;
          return {
            id: identifier,
            companyName: name,
            customerCount: statistics.customerCount,
            rewardQuantity: statistics.rewardQuantity,
            claimedRewardCount: statistics.claimedRewardCount,
            ...rest,
          };
        }),
      };
  }, [data]);

  const { showNotification } = useNotificationsContext();

  const [deletedItem, setDeletedItem] = useState<DeletedItem>(null);
  const { mutate: deleteCompanyMutation, isPending: isDeleteMutationPending } =
    useMutation<any, AxiosError, any>({
      mutationFn: (id) => deleteCompany(id),
      onSuccess: () => {
        showNotification(t("Companies.companyDeleted"));
        setDeletedItem(null);
        refetch();
      },
      onError: () => {
        showNotification(t("Companies.companyDeleteFail"), {
          type: "error",
        });
        setDeletedItem(null);
      },
    });

  return (
    <Box>
      <DataTable
        handlePageChange={setPageNumber}
        handlePageSizeChange={setPageSize}
        pageSize={pageSize}
        isFetching={isLoading}
        data={companiesList}
        showActions={{
          handleDelete: (id) => {
            setDeletedItem({
              id,
              name: companiesList?.items.find((i) => i.id === id)?.companyName!,
            });
          },
          handleEdit: true,
          isDeleteMutationPending,
        }}
      />
      <ConfirmModal
        open={!!deletedItem?.id}
        handleNo={() => setDeletedItem({ ...deletedItem!, id: "" })}
        handleYes={() => {
          setDeletedItem({ ...deletedItem!, id: "" });
          deleteCompanyMutation(deletedItem!.id);
        }}
        text={t("Companies.companyDeleteConfirm", {
          name: deletedItem?.name,
        })}
      />
    </Box>
  );
};
