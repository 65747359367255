import { Box, Typography } from "@mui/material";
import { creditCardIcon } from "../..";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import { getImageUrl } from "@repo/utils";
import { useContext } from "react";
import { PaymentMethodVm } from "@repo/types/companyApi.types";

interface CardInfoProps {
  data: PaymentMethodVm;
}

export const CardInfo = ({ data }: CardInfoProps) => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          gap: 1,
        }}
      >
        <img src={getImageUrl(creditCardIcon)} alt="" />
        <Typography>**** {data.lastFour}</Typography>
      </Box>
      <Typography
        sx={{
          mt: 1,
          color: (theme) => theme.palette.text.secondary,
        }}
      >
        {t("dashboard.sidebar.expires")}: {data.expirationDate}
      </Typography>
    </Box>
  );
};
