import { createFileRoute, redirect } from "@tanstack/react-router";
import RewardsPage from "../../../pages/Rewards/RewardsPage";

export const Route = createFileRoute("/_private/rewards/")({
  component: () => <RewardsPage />,
  beforeLoad: ({ context }) => {
    const { canRead } = context.permissions.rewards;

    if (!canRead) {
      throw redirect<any>({
        to: "/",
      });
    }
  },
});
