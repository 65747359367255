import {
  AccessTokenResponse,
  ForgotPasswordRequest,
  InfoRequest,
  LoginRequest,
  RefreshRequest,
  ResendConfirmationEmailRequest,
  ResetPasswordRequest,
  UserInfoResponse,
} from "@repo/types/customerApi.types";
import { customerApiPaths } from "./paths";
import { httpClient } from "../../http-client";

export interface EmailConfirmationRequest {
  userId: string;
  code: string;
  changedEmail?: string;
}

export const signIn = async ({ email, password }: LoginRequest) => {
  const response = await httpClient.post<AccessTokenResponse>(
    customerApiPaths.auth.login,
    {
      email,
      password,
    }
  );
  return response.data;
};

export const registerCustomer = async ({ email, password }: LoginRequest) => {
  const response = await httpClient.post(customerApiPaths.auth.register, {
    email,
    password,
  });
  return response.data;
};

export const confirmEmail = async (params: EmailConfirmationRequest) => {
  const response = await httpClient.post(
    customerApiPaths.auth.confirmEmail,
    undefined,
    {
      params,
    }
  );
  return response.data;
};

export const resendConfirmationEmail = async ({
  email,
}: ResendConfirmationEmailRequest) => {
  const response = await httpClient.post(
    customerApiPaths.auth.resendConfirmationEmail,
    {
      email,
    }
  );

  return response.data;
};

export const refreshToken = async ({ refreshToken }: RefreshRequest) => {
  const response = await httpClient.post<AccessTokenResponse>(
    customerApiPaths.auth.refresh,
    {
      refreshToken,
    }
  );
  return response.data;
};

export const getUserInfo = async () => {
  const response = await httpClient.get<UserInfoResponse>(
    customerApiPaths.auth.info
  );

  return response.data;
};

export const forgotPassword = async ({ email }: ForgotPasswordRequest) => {
  const response = await httpClient.post(customerApiPaths.auth.forgotPassword, {
    email,
  });

  return response.data;
};

export const resetPassword = async ({
  email,
  newPassword,
  resetCode,
}: ResetPasswordRequest) => {
  const response = await httpClient.post(customerApiPaths.auth.resetPassword, {
    email,
    newPassword,
    resetCode,
  });

  return response.data;
};

export const changeUserEmail = async ({ newEmail }: InfoRequest) => {
  const response = await httpClient.patch(customerApiPaths.auth.info, {
    newEmail,
  });

  return response.data;
};

export const changeLoggedUserPassword = async ({
  oldPassword,
  newPassword,
}: InfoRequest) => {
  const response = await httpClient.patch(customerApiPaths.auth.info, {
    oldPassword,
    newPassword,
  });

  return response.data;
};

export const deleteAccount = async () => {
  const response = await httpClient.delete(customerApiPaths.auth.base);

  return response.data;
};
