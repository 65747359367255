import { createFileRoute, redirect } from "@tanstack/react-router";
import { LoginPage } from "../../../pages/Login";

export const Route = createFileRoute("/_public/login/")({
  component: LoginPage,
  beforeLoad: ({ context }) => {
    const { isAuthenticated } = context.auth;

    if (isAuthenticated()) {
      throw redirect<any>({
        to: "/",
      });
    }
  },
});
