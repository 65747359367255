import {
  StripeClientSecretDto,
  StripePublishableKeyVm,
} from "@repo/types/companyApi.types";
import { httpClient } from "../../http-client";
import { companyApiPaths } from "./paths";

export const generateStripeSecret = async (tenantId: string) => {
  const response = await httpClient.post<StripeClientSecretDto>(
    companyApiPaths.stripe.secret(tenantId)
  );

  return response.data.clientSecret;
};

export const getPublishableKey = async (tenantId: string) => {
  const response = await httpClient.get<StripePublishableKeyVm>(
    companyApiPaths.stripe.publishableKey(tenantId)
  );

  return response.data.publishableKey;
};
