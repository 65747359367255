import { useCompanyAuth, useRootAuth } from "@repo/api-config";
import { getUserInfo, SignInRequest } from "@repo/api-config/services/company";
import { getUserInfo as getRootUserInfo } from "@repo/api-config/services/root";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import {
  eraseCookie,
  setCookie,
  useNotificationsContext,
  useServerErrorFormatter,
  webAppRoutes,
} from "@repo/utils";
import { useForm } from "@tanstack/react-form";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { valibotValidator } from "@tanstack/valibot-form-adapter";
import { AxiosError } from "axios";
import { useContext } from "react";

interface UseLoginFormProps {
  isRootLogin?: boolean;
}

export const useLoginForm = ({ isRootLogin = false }: UseLoginFormProps) => {
  const auth = isRootLogin ? useRootAuth() : useCompanyAuth();
  const navigate = useNavigate();
  const { errorMessage, setError } = useServerErrorFormatter();
  const { lang } = useContext(I18nContext);
  const { t: commonT } = useTranslation(lang, "common");
  const { showNotification } = useNotificationsContext();

  const { mutate: loginMutation, isPending } = useMutation<
    void,
    AxiosError,
    SignInRequest
  >({
    mutationFn: (values) => auth.signIn(values),
    onSuccess: async () => {
      showNotification(commonT("loggedIn"));
      eraseCookie("loymeeUserRole");
      if (isRootLogin) {
        const data = await getRootUserInfo();
        setCookie(
          "loymeeUserRole",
          data.userRole || "Admin",
          14 * 24 * 60 * 60 * 1000
        );
        setCookie(
          "loymeeTenantId",
          data.tenantIdentifier || "",
          14 * 24 * 60 * 60 * 1000
        );
        return navigate({ to: "/", params: { admin: true } });
      }

      const data = await getUserInfo(form.getFieldValue("tenantId").trim());
      setCookie(
        "loymeeUserRole",
        data.userRole || "Owner",
        14 * 24 * 60 * 60 * 1000
      );
      setCookie(
        "loymeeTenantId",
        data.tenantIdentifier || "",
        14 * 24 * 60 * 60 * 1000
      );
      if (data.userRole === "Operator") {
        window.location.href = `${import.meta.env.VITE_WEBAPP_URL}/${webAppRoutes.employee.scan}`;
      } else if (data.userRole?.includes("Owner")) {
        navigate({ to: "/" });
      } else {
        auth.logout();
      }
    },
    onError: setError,
  });

  const form = useForm<SignInRequest, any>({
    defaultValues: {
      email: "",
      password: "",
      tenantId: "",
    },
    onSubmit: ({ value }) => {
      loginMutation({
        email: value.email.trim(),
        password: value.password.trim(),
        tenantId: value.tenantId.trim(),
      });
    },
    validatorAdapter: valibotValidator(),
  });

  return {
    form,
    isPending,
    errorMessage,
  };
};
