"use client";

import { useState, useEffect, useContext } from "react";
import { useNotificationsContext } from "./NotificationsContext";
import { I18nContext, useTranslation } from "../i18n-config";

interface Location {
  latitude: number | null;
  longitude: number | null;
}

interface UseGeolocationReturn {
  location: Location;
  error?: string;
  loading: boolean;
}

export const useGeolocation = (): UseGeolocationReturn => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "common");

  const { showNotification } = useNotificationsContext();
  const [location, setLocation] = useState<Location>({
    latitude: null,
    longitude: null,
  });
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!navigator.geolocation) {
      setError(t("geolocation.notAvailableInBrowser"));
      showNotification(t("geolocation.notAvailableInBrowser"), {
        type: "error",
      });
      setLoading(false);
      return;
    }

    const handleSuccess = (position: GeolocationPosition) => {
      const { latitude, longitude } = position.coords;
      setLocation({ latitude, longitude });
      setLoading(false);
    };

    const handleError = (error: GeolocationPositionError) => {
      setError(String(error.message));
      switch (error.code) {
        case error.PERMISSION_DENIED:
          showNotification(t("geolocation.permissionDenied"), {
            type: "error",
          });
          break;
        case error.POSITION_UNAVAILABLE:
          showNotification(t("geolocation.positionUnavailable"), {
            type: "error",
          });
          break;
        case error.TIMEOUT:
          showNotification(t("geolocation.timeout"), { type: "error" });
          break;
        default:
          showNotification(t("geolocation.genericError"), {
            type: "error",
          });
          break;
      }
      setLoading(false);
    };

    const options: PositionOptions = {
      enableHighAccuracy: true,
      timeout: 10000,
      maximumAge: 0,
    };

    navigator.geolocation.getCurrentPosition(
      handleSuccess,
      handleError,
      options
    );
  }, []);

  return { location, error, loading };
};
