"use client";

import SubscriptionOptions from "./SubscriptionOptions";
import PaymentsInfo from "./PaymentsInfo";
import { ChangePassword } from "./ChangePassword";
import { EmailInfo } from "./EmailInfo";
import { Box } from "@mui/material";
import CompanyInfo from "./CompanyInfo";

const SettingsPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <CompanyInfo />
      <PaymentsInfo />
      <SubscriptionOptions />
      <EmailInfo />
      <ChangePassword />
    </Box>
  );
};
export default SettingsPage;
