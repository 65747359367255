import { Box, FormGroup } from "@mui/material";
import { getTenantId } from "@repo/api-config";
import { addEmployee, editEmployee } from "@repo/api-config/services/company";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import {
  RegisterEmployeeRequest,
  UpdateEmployeeCommand,
} from "@repo/types/companyApi.types";
import { Button, Card, ErrorMessage, TextInput } from "@repo/ui";
import {
  cmsRoutes,
  emailValidator,
  getCookie,
  passwordValidator,
  useNotificationsContext,
  useServerErrorFormatter,
} from "@repo/utils";
import { FormApi, useForm } from "@tanstack/react-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useLoaderData, useNavigate } from "@tanstack/react-router";
import { valibotValidator } from "@tanstack/valibot-form-adapter";
import { AxiosError } from "axios";
import { useContext } from "react";

export const NewEmployee = () => {
  const { showNotification } = useNotificationsContext();
  const { errorMessage, setError } = useServerErrorFormatter();
  const navigate = useNavigate();
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");
  const tenantId = getTenantId();
  const queryClient = useQueryClient();
  const isPendingOwner = getCookie("loymeeUserRole") === "PendingOwner";

  const { mutate: addEmployeeMutation } = useMutation<
    void,
    AxiosError,
    RegisterEmployeeRequest
  >({
    mutationFn: (values) => addEmployee(tenantId!, values),
    onError: setError,
    onSuccess: () => {
      showNotification(t("Employees.employeeAdded"));
      queryClient.invalidateQueries({
        queryKey: ["employees"],
      });
      navigate({
        to: isPendingOwner
          ? `${cmsRoutes.employees.base}?pending=true`
          : cmsRoutes.employees.base,
      });
    },
  });

  const form = useForm<RegisterEmployeeRequest, any>({
    defaultValues: {
      email: "",
      password: "",
    },
    onSubmit: ({ value }) => {
      addEmployeeMutation({
        email: value.email,
        password: value.password,
      });
    },
    validatorAdapter: valibotValidator(),
  });

  return (
    <Card
      title={t("Employees.addEmployee")}
      sx={{
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        "& h6": {
          width: { xs: "100%", sm: "40%" },
        },
      }}
    >
      <EmployeeForm form={form} errorMessage={errorMessage} />
    </Card>
  );
};

export const EditEmployee = () => {
  const loaderData = useLoaderData({
    from: "/_private/employees/edit/$employeeId",
  });
  const { errorMessage, setError } = useServerErrorFormatter();
  const navigate = useNavigate();
  const { showNotification } = useNotificationsContext();
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");
  const tenantId = getTenantId();
  const queryClient = useQueryClient();

  const { mutate: editEmployeeMutation } = useMutation<
    void,
    AxiosError,
    UpdateEmployeeCommand
  >({
    mutationFn: (values) => editEmployee(tenantId!, values),
    onError: setError,
    onSuccess: () => {
      showNotification(t("Employees.employeeEdited"));
      queryClient.invalidateQueries({
        queryKey: ["employees"],
      });
      navigate({ to: cmsRoutes.employees.base });
    },
  });

  const form = useForm<RegisterEmployeeRequest, any>({
    defaultValues: {
      email: loaderData.email || "",
      password: "",
    },
    onSubmit: ({ value }) => {
      editEmployeeMutation({
        id: loaderData.id,
        ...(value.email !== loaderData.email ? { email: value.email } : {}),
        newPassword: value.password,
      });
    },
    validatorAdapter: valibotValidator(),
  });

  return (
    <Card
      title={t("Employees.editCardTitle")}
      sx={{
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        "& h6": {
          width: { xs: "100%", sm: "40%" },
        },
      }}
    >
      <EmployeeForm form={form} errorMessage={errorMessage} />
    </Card>
  );
};

interface EmployeeFormProps {
  form: FormApi<any, any>;
  errorMessage?: string;
}

const EmployeeForm = ({ form, errorMessage }: EmployeeFormProps) => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");

  return (
    <Box
      component="form"
      sx={{ display: "flex", flex: 1 }}
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <FormGroup sx={{ gap: 2 }}>
          <TextInput
            form={form}
            name="email"
            type="email"
            validators={{
              onChange: emailValidator,
            }}
            label={t("Employees.form.email")}
            required
          />
          <TextInput
            form={form}
            type="password"
            name="password"
            validators={{
              onChange: passwordValidator,
            }}
            label={t("Employees.form.password")}
            autoComplete="new-password"
            required
          />
        </FormGroup>
        {errorMessage ? <ErrorMessage errorMessage={errorMessage} /> : null}
        <form.Subscribe
          selector={(state) => state.isValid && !state.isPristine}
          children={(isValid) => (
            <Button
              type="submit"
              sx={{ mt: 4, alignSelf: "flex-end" }}
              variant="contained"
              disabled={!isValid}
            >
              {t("Employees.form.save")}
            </Button>
          )}
        />
      </Box>
    </Box>
  );
};
