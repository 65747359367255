import { createFileRoute, redirect } from "@tanstack/react-router";
import { SubscriptionsPage } from "../../../pages/Subscriptions/SubscriptionsPage";

export const Route = createFileRoute("/_private/subscriptions/")({
  component: () => <SubscriptionsPage />,
  beforeLoad: ({ context }) => {
    const { getUserRole } = context.auth;
    const role = getUserRole();

    if (role !== "Admin") {
      throw redirect<any>({
        to: "/",
      });
    }
  },
});
