import { Box, CircularProgress, TextField } from "@mui/material";
import { getTenantId } from "@repo/api-config";
import {
  getCompanyDetails,
  updateCompany,
} from "@repo/api-config/services/company";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import { UpdateCompanyCommand } from "@repo/types/companyApi.types";
import { Card, ErrorMessage, LoadingButton } from "@repo/ui";
import { useNotificationsContext, useServerErrorFormatter } from "@repo/utils";
import { useForm } from "@tanstack/react-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { valibotValidator } from "@tanstack/valibot-form-adapter";
import { AxiosError } from "axios";
import { useContext, useEffect } from "react";

const Points = () => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");

  const tenantId = getTenantId();

  const { errorMessage, setError } = useServerErrorFormatter();

  const { showNotification } = useNotificationsContext();

  const { data, isLoading, error } = useQuery({
    queryKey: ["Multiplier"],
    queryFn: () => getCompanyDetails(tenantId!),
  });

  useEffect(() => {
    if (error) setError(error as AxiosError);
  }, [error]);

  const form = useForm({
    defaultValues: {
      multiplier: 1,
    },
    onSubmit: async ({ value }) => {
      mutate(value);
    },
    validatorAdapter: valibotValidator(),
  });

  useEffect(() => {
    if (data) form.setFieldValue("multiplier", data.multiplier);
  }, [data]);

  const { mutate, isPending } = useMutation<
    void,
    AxiosError,
    Pick<UpdateCompanyCommand, "multiplier">
  >({
    mutationFn: (value) => updateCompany(tenantId!, value),
    onSuccess: () => {
      showNotification(t("Rewards.multiplierSaved"));
    },
    onError: (error) => setError(error),
  });

  return (
    <Card
      title={t("Rewards.points.pointsPerScan")}
      sx={{
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        "& h6": {
          width: { xs: "100%", sm: "40%" },
        },
      }}
    >
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {errorMessage && !data && <ErrorMessage errorMessage={errorMessage} />}
      {data && (
        <Box
          component="form"
          sx={{ display: "flex", flex: 1 }}
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            form.handleSubmit();
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <form.Field
              name="multiplier"
              children={(field) => (
                <TextField
                  type="number"
                  label={t("Rewards.points.multiplier")}
                  fullWidth
                  name={field.name}
                  value={field.state.value}
                  onChange={(e) => {
                    field.handleChange(Number(e.target.value));
                  }}
                />
              )}
            />
            {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
            <LoadingButton
              sx={{
                mt: 4,
                alignSelf: "flex-end",
                backgroundColor: (theme) => theme.palette.secondary.main,
              }}
              fullWidth={false}
              text={t("Rewards.points.save")}
              isLoading={isPending}
            />
          </Box>
        </Box>
      )}
    </Card>
  );
};

export default Points;
