import { Add } from "@mui/icons-material";
import {
  Button,
  Box,
  CardActions,
  CardHeader,
  Typography,
  Dialog,
  DialogContent,
} from "@mui/material";
import { getTenantId } from "@repo/api-config";
import {
  deleteReward,
  getFlags,
  getRewardsList,
} from "@repo/api-config/services/company";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import { Card, ConfirmModal, LinkWrapper } from "@repo/ui";
import { cmsRoutes, useNotificationsContext } from "@repo/utils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Link, useRouter } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { useContext, useEffect, useState } from "react";
import { DeletedItem } from "../../@types";

const Rewards = () => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");
  const tenantId = getTenantId();
  const { state } = useRouter();
  const [pendingOwnerModalOpen, setPendingOwnerModalOpen] = useState(false);

  const { showNotification } = useNotificationsContext();
  const { data: rewards, refetch: refetchRewards } = useQuery({
    queryKey: ["rewards"],
    queryFn: () => getRewardsList(tenantId!),
    enabled: !!tenantId,
    refetchOnMount: true,
  });

  useEffect(() => {
    if (
      state.location.searchStr.includes("?pending=true") &&
      rewards?.length === 0
    ) {
      setPendingOwnerModalOpen(true);
    }
  }, []);

  const { data: flags, refetch: refetchFlags } = useQuery({
    queryKey: ["flags"],
    queryFn: () => getFlags(tenantId!),
    refetchOnMount: true,
  });

  const [deletedItem, setDeletedItem] = useState<DeletedItem>(null);
  const { mutate: deleteRewardMutation } = useMutation<
    void,
    AxiosError,
    number
  >({
    mutationFn: (id) => deleteReward(tenantId!, id),
    onSuccess: () => {
      showNotification(t("Rewards.rewardDeleted"));
      refetchRewards();
      refetchFlags();
    },
    onError: () => {
      showNotification(t("Rewards.rewardDeleteError"), {
        type: "error",
      });
    },
  });

  return (
    <Card>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">{`${t("Rewards.yourRewards")} - ${rewards?.length}/${flags?.rewardLimit ?? 1}`}</Typography>
        <Button
          component={Link}
          to="/rewards/new"
          sx={{
            flexShrink: 0,
            ml: 2,
          }}
          disabled={
            flags?.rewardLimitReached ?? (rewards && rewards.length > 1)
          }
        >
          <Add />
          {t("Rewards.addNew")}
        </Button>
      </Box>
      <Box
        sx={{
          display: "grid",
          rowGap: 4,
          columnGap: 6,
          gridTemplateColumns: {
            xs: "1fr",
            sm: "1fr 1fr",
            lg: "1fr 1fr 1fr",
          },
        }}
      >
        {rewards?.map((reward) => (
          <Card
            key={reward.id}
            elevation={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              border: "none",
              minHeight: 160,
            }}
          >
            <CardHeader
              title={reward.name}
              subheader={`${t("Rewards.amountToRealize")}: ${reward.pointCost}`}
            />
            <CardActions
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                color="error"
                onClick={() =>
                  setDeletedItem({
                    id: reward.id,
                    name: reward.name,
                  })
                }
              >
                {t("Rewards.delete")}
              </Button>
              <Button
                color="secondary"
                component={Link}
                to="/rewards/edit/$rewardId"
                params={{ rewardId: reward.id.toString() }}
              >
                {t("Rewards.edit")}
              </Button>
            </CardActions>
          </Card>
        ))}
        <ConfirmModal
          open={!!deletedItem?.id}
          handleNo={() => setDeletedItem({ ...deletedItem!, id: "" })}
          handleYes={() => {
            setDeletedItem({ ...deletedItem!, id: "" });
            deleteRewardMutation(deletedItem!.id as number);
          }}
          text={t("Rewards.rewardDeleteConfirm", { name: deletedItem?.name })}
        />
      </Box>
      <Dialog open={pendingOwnerModalOpen} fullWidth maxWidth="sm">
        <DialogContent>
          {t("Rewards.pendingOwnerModal.content")}
          <Box sx={{ mt: 2, display: "flex", justifyContent: "end", gap: 1 }}>
            <LinkWrapper to={cmsRoutes.home.base}>
              <Button onClick={() => setPendingOwnerModalOpen(false)}>
                {t("Rewards.pendingOwnerModal.continue")}
              </Button>
            </LinkWrapper>
            <Button
              variant="text"
              onClick={() => setPendingOwnerModalOpen(false)}
            >
              {t("Rewards.pendingOwnerModal.next")}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default Rewards;
