import { Box, TextField } from "@mui/material";
import { useMe } from "@repo/api-config";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import { Card } from "@repo/ui";
import { useContext } from "react";

export const EmailInfo = () => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");
  const { email } = useMe("company");

  return (
    <Card
      title={t("Settings.emailAddress")}
      sx={{
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        "& h6": {
          width: { xs: "100%", sm: "40%" },
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <TextField
          label={t("Settings.emailAddress")}
          value={email || ""}
          disabled
        />
      </Box>
    </Card>
  );
};
