import { Typography } from "@mui/material";

interface ErrorMessageProps {
  errorMessage: string;
}

export const ErrorMessage = ({ errorMessage }: ErrorMessageProps) => {
  return (
    <Typography variant="body2" color="error" mt={1}>
      {errorMessage}
    </Typography>
  );
};
