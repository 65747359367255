import { Box, FormGroup, Typography } from "@mui/material";
import { Card, LoadingButton, Logo, TextInput } from "@repo/ui";
import {
  emailValidator,
  requiredStringValidator,
  useNotificationsContext,
} from "@repo/utils";
import { useForm } from "@tanstack/react-form";
import { valibotValidator } from "@tanstack/valibot-form-adapter";
import { AxiosError } from "axios";
import { useMutation } from "@tanstack/react-query";
import {
  forgotPassword,
  ForgotPasswordFormRequest,
} from "@repo/api-config/services/company";
import { useContext } from "react";
import { I18nContext, useTranslation } from "@repo/i18n-config";

export const ForgotPasswordPage = () => {
  const { showNotification } = useNotificationsContext();
  const { mutate: sendForm, isPending } = useMutation<
    void,
    AxiosError,
    ForgotPasswordFormRequest
  >({
    mutationFn: forgotPassword,
    onSuccess: () => showNotification(t("ForgotPassword.emailSent")),
    onError: () => showNotification(t("ForgotPassword.emailSent")),
  });
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");

  const form = useForm<any, any>({
    defaultValues: {
      tenantId: "",
      email: "",
    },
    onSubmit: async ({ value }) => {
      sendForm(value);
    },
    validatorAdapter: valibotValidator(),
  });

  return (
    <Card fullscreen>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mb: 3,
        }}
      >
        <Logo height={50} theme="dark" />
      </Box>
      <Typography variant="h5">{t("ForgotPassword.header")}</Typography>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
      >
        <FormGroup sx={{ gap: 2, mt: 1 }}>
          <TextInput
            form={form}
            name="tenantId"
            validators={{
              onChange: requiredStringValidator,
            }}
            label={t("ForgotPassword.form.tenantId")}
            required
          />
          <TextInput
            form={form}
            name="email"
            validators={{
              onChange: emailValidator,
            }}
            type="email"
            label={t("ForgotPassword.form.email")}
            required
          />
          <LoadingButton
            isLoading={isPending}
            text={t("ForgotPassword.form.send")}
            size="large"
          />
        </FormGroup>
      </form>
    </Card>
  );
};
