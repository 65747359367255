import { useContext, useEffect, useState } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { getTenantId } from "@repo/api-config";
import {
  getCurrentSubscription,
  getPlans,
} from "@repo/api-config/services/company";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import { Card, ErrorMessage } from "@repo/ui";
import { useServerErrorFormatter } from "@repo/utils";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { SubscriptionCard } from "./SubscriptionCard";
import { SubscriptionSettingsModal } from "./SubscriptionSettingsModal";
import { SubscriptionState } from "@repo/types/companyApi.types";

const SubscriptionOptions = () => {
  const { errorMessage, setError } = useServerErrorFormatter();
  const tenantId = getTenantId();

  const {
    data: subscriptionOptions,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["subscriptions"],
    queryFn: () => getPlans(tenantId!, { pageNumber: 1, pageSize: 10 }),
  });

  const { data: currentSubscription, isLoading: currentSubscriptionLoading } =
    useQuery({
      queryKey: ["currentSubscription"],
      queryFn: () => getCurrentSubscription(tenantId!),
      enabled: !!subscriptionOptions,
    });

  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");

  const subscriptions =
    subscriptionOptions &&
    [
      subscriptionOptions.currentCustomPlan!,
      ...subscriptionOptions.items,
    ].filter(Boolean);

  useEffect(() => {
    if (error) setError(error as AxiosError);
  }, [error]);

  const [settingsModalOpen, setSettingsModalOpen] = useState(false);

  return (
    <div id="plans">
      <Card
        title={t("Rewards.subscriptionOptions.cardTitle")}
        description={t("Rewards.subscriptionOptions.cardDescription")}
        sx={{
          flexDirection: {
            xs: "column",
            md: "row",
          },
          "& h6": {
            width: { xs: "100%", md: "40%" },
          },
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", flex: 1, gap: 2 }}>
          {(isLoading || currentSubscriptionLoading) && (
            <CircularProgress size={28} />
          )}
          {error && <ErrorMessage errorMessage={errorMessage} />}
          {(!isLoading || !currentSubscriptionLoading) && !error && (
            <>
              {currentSubscription &&
                currentSubscription.state !== SubscriptionState.Cancelled && (
                  <>
                    <Button
                      color="secondary"
                      sx={{
                        alignSelf: "end",
                      }}
                      onClick={() => setSettingsModalOpen(true)}
                    >
                      {t("Rewards.subscriptionOptions.subscriptionSettings")}
                    </Button>
                    <SubscriptionSettingsModal
                      open={settingsModalOpen}
                      onClose={() => setSettingsModalOpen(false)}
                      currentSubscription={currentSubscription}
                    />
                  </>
                )}
              {subscriptions?.map((item) => (
                <SubscriptionCard item={item} key={item.id} />
              ))}
            </>
          )}
        </Box>
      </Card>
    </div>
  );
};

export default SubscriptionOptions;
